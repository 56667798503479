import React, { Fragment } from "react";
import dayjs from "dayjs";
import { BUTTON_TEXT } from "../../../utils/StringConstant";
import { apiConstants } from "../../../utils/Constants";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import "./style.scss";

type Props = {
  [key: string]: any;
};

const EventCardSimple = ({
  item,
  handleEventEditClick,
  onDeleteEvent,
  index,
  watch,
}: Props) => {
  return (
    <div className="simple_card_wrap">
      <div className="header_card">
        <div className="heading_1">{item.title}</div>
        <div className="action_card">
          <img
            src="/static/createListingIcon/editPencil.svg"
            alt=""
            onClick={() => handleEventEditClick(item)}
          />
          <img
            src="/static/createListingIcon/deleteTheme.svg"
            alt=""
            onClick={() => onDeleteEvent(index)}
          />
        </div>
      </div>

      {watch(apiConstants.listing_event_type) !== "V" &&
        item?.address?.address && (
          <div className="body_1">
            <img src="/static/createListingIcon/location.svg" alt="" />
            <div>{item?.address?.address}</div>
          </div>
        )}
      <div className="d-flex gap-4 align-items-center">
        <div className="body_1">
          <img src="/static/createListingIcon/calendar.svg" alt="" />
          <div>{dayjs(item?.date, "YYYY-MM-DD").format("DD MMM, YYYY")}</div>
        </div>
        <div className="body_1">
          <img src="/static/createListingIcon/clockOutlineGrey.svg" alt="" />
          <div>{`${dayjs(
            item?.date + " " + item?.start_time,
            "YYYY-MM-DD HH:mm:ss.SSSSSS"
          ).format("hh:mm A")} - ${dayjs(
            item?.date + " " + item?.end_time,
            "YYYY-MM-DD HH:mm:ss.SSSSSS"
          ).format("hh:mm A")}`}</div>
        </div>
      </div>
      {watch(apiConstants.listing_event_type) === "V" &&
        watch(apiConstants.multiple_virtual_links_allowed) === true &&
        watch(apiConstants.virtual_links_single) && (
          <div className="body_1">
            <img src="/static/createListingIcon/linkGrey.svg" alt="" />
            <div>{watch(apiConstants.virtual_links_single) || "-"}</div>
          </div>
        )}

      {watch(apiConstants.listing_event_type) === "V" &&
        watch(apiConstants.multiple_virtual_links_allowed) === false &&
        item?.virtual_links_package && (
          <div className="body_1">
            <img src="/static/createListingIcon/linkGrey.svg" alt="" />
            <div>{item?.virtual_links_package || "-"}</div>
          </div>
        )}
    </div>
  );
};

const EventCardSection = (props: Props) => {
  const {
    data = [],
    handleEventEditClick,
    onDeleteEvent,
    setShowEventListingsSidebar,
    viewAll = true,
    totalData = 0,
    watch,
  } = props;
  return (
    <div className="event_card_container">
      {data?.map((item, index) => (
        <Fragment key={"package_event" + index}>
          <EventCardSimple
            item={item}
            handleEventEditClick={handleEventEditClick}
            onDeleteEvent={onDeleteEvent}
            index={index}
            watch={watch}
          />
          {data?.length - 1 !== index && <HorizontalLine />}
        </Fragment>
      ))}

      {viewAll && totalData > 2 && (
        <div className="view_all">
          <div
            className="viw_btn"
            onClick={() => setShowEventListingsSidebar(true)}
          >
            <div>
              <span style={{ textDecoration: "underline" }}>
                {BUTTON_TEXT.VIEW_ALL_EVENT}
              </span>{" "}
              <span style={{ textDecoration: "none" }}>{`(${totalData})`}</span>
            </div>
            <img src="/static/createListingIcon/forwardArrowBack.svg" alt="" />
          </div>
        </div>
      )}
    </div>
  );
};

export default EventCardSection;

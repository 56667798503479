import React, { memo, useEffect, useRef, useState } from "react";
import { showToast } from "../../../utils";
import apiConfig from "../../../utils/endpoints";
import { MESSAGE } from "../../../utils/StringConstant";
import { makeDeleteCall } from "../../../utils/Requests";
import ApiLoader from "../../../global/ApiLoader";
import "./style.scss";

type Props = {
  handleMediaFIles?: any;
  images: any;
  setImages: any;
  isMediaUploadComplete: any;
  mediaUploadPayload: any;
  setMediaUploadPayload: any;
};

const FilesUploadComponent = ({
  handleMediaFIles,
  images = [],
  setImages,
  isMediaUploadComplete,
  mediaUploadPayload,
  setMediaUploadPayload,
}: Props) => {
  const fileInputRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = () => {
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageUpload = (event, drag = false) => {
    let files = [];
    if (drag) {
      files = Array.from(event.dataTransfer.files);
    } else {
      files = Array.from(event.target.files);
    }
    const maxFiles = 5;
    const maxVideoFiles = 1;
    const maxSize = 20 * 1024 * 1024; // 20 MB

    //files limit 5
    if (images.length + files.length > maxFiles) {
      showToast(MESSAGE.MAX_MEDIA_FILES, "error");
      return;
    }

    // file: video and image
    const invalidFiles = files.filter((file: File) => {
      const isValidType =
        file.type.startsWith("image") || file.type.startsWith("video");

      return !isValidType;
    });
    if (invalidFiles.length > 0) {
      showToast(MESSAGE.INVALID_FILE_TYPE, "error");
      return;
    }

    // max file size 20mb
    const largeFiles = files.filter((file: File) => {
      const isValidSize = file.size <= maxSize;

      return !isValidSize;
    });
    if (largeFiles.length > 0) {
      showToast(MESSAGE.MAX_FILE_SIZE, "error");
      return;
    }

    // max one video
    const videoFilesCount = files.filter((file: File) =>
      file.type.startsWith("video")
    ).length;
    const videoFilesCountNewUpload = images.filter((file: any) =>
      file?.documentFileType?.startsWith("video")
    ).length;
    if (videoFilesCount + videoFilesCountNewUpload > maxVideoFiles) {
      showToast(MESSAGE.MAX_VIDEO_FILES, "error");
      return;
    }
    const newFiles = Array.from(files).map((file) => ({
      file: file,
      uploadDocumentUrl: "",
      documentFileType: file.type,
      blobUrl: handleBlobUrl(file),
      tempId: `${Date.now()}-${Math.random()?.toString(36)?.substring(2, 9)}`,
    }));
    setImages((prevImages) => [...prevImages, ...newFiles]);
    setIsLoading(true);
  };

  const handleBlobUrl = (file: File) => {
    return URL.createObjectURL(file);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    handleImageUpload(event, true);
  };

  useEffect(() => {
    handleMediaFIles(images);
  }, [images]);

  useEffect(() => {
    if (isMediaUploadComplete) {
      setIsLoading(false);
    }
  }, [isMediaUploadComplete]);

  const handleDeleteFromPayload = (itemId: any) => {
    if (itemId) {
      const filteredPayload = mediaUploadPayload?.filter(
        (payload) => payload?.tempId !== itemId
      );
      setMediaUploadPayload(filteredPayload);
    }
  };

  const handleImageDelete = async (item, index) => {
    if (item?.id) {
      await makeDeleteCall({
        url: `${apiConfig.listing}${item?.listing}`,
        apiPayload: {
          document_id: item?.id,
        },
        content_type: "application/json",
      })
        .then((res) => {
          if (res.status.code === 200) {
            showToast(res.status.message, "success");
            setImages(images.filter((_, i) => i !== index));
            handleDeleteFromPayload(item?.tempId);
          }
        })
        .catch((err) => showToast(err, "error", "errmess43"));
    } else {
      setImages(images.filter((_, i) => i !== index));
      handleDeleteFromPayload(item?.tempId);
    }
  };

  const UploadContainer = ({ subtitle = false }) => {
    return (
      <div
        className={`container_file0 file_wrap ${dragging ? "dragging" : ""}`}
        onClick={handleUploadClick}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          multiple
          accept="image/*,video/*"
          ref={fileInputRef}
          onInput={handleImageUpload}
          style={{ display: "none" }}
        />
        <div>
          <img src="/static/uploadimg.svg" alt="" />
        </div>
        <div className="upload_file_heading">
          Drag and drop your listing photo or video
        </div>
        {subtitle && (
          <div className="upload_file_subheading">
            Or, click to browse (20 MB max)
          </div>
        )}
      </div>
    );
  };

  const FileContainer = ({ className = "", src, index }) => {
    return (
      <div className={className}>
        {src.documentFileType.startsWith("video") ? (
          <video
            src={src?.uploadDocumentUrl || src?.blobUrl}
            autoPlay
            muted
            className="uploaded_file"
          />
        ) : (
          <img
            src={src?.uploadDocumentUrl || src?.blobUrl}
            alt=""
            className="uploaded_file"
          />
        )}
        <div
          className="delete_container"
          onClick={() => handleImageDelete(src, index)}
        >
          <img src={'/static/common/delete.svg'} alt="" className="uploaded_file" />
        </div>
      </div>
    );
  };

  const handleMediaFilesPreview = (qty = 0, files = []) => {
    const renderFileContainers = () => {
      return files
        .slice(0, qty)
        .map((file, index) => (
          <FileContainer
            key={index}
            className={`container_file${index + 1} file_wrap`}
            src={file}
            index={index}
          />
        ));
    };

    const gridClasses = [
      "grid_empty", // 0
      "grid_first", // 1
      "grid_second", // 2
      "grid_third", // 3
      "grid_fourth", // 4
      "grid_fifth", // 5
    ];

    const gridClass = gridClasses[qty] || "grid_empty";

    return (
      <div className={`file_container ${gridClass}`}>
        {renderFileContainers()}
        {qty < 5 && <UploadContainer subtitle={qty === 0} />}
      </div>
    );
  };

  return (
    <div>
      <div className="create_listing_wrapper">
        <div className="listing_container">
          <div className="files_wrapper">
            {!isLoading && handleMediaFilesPreview(images.length, images)}
            {isLoading && (
              <div className="file_container">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    gap: "42px",
                  }}
                >
                  <div style={{ height: "24px", width: "24px" }}>
                    <ApiLoader />
                  </div>
                  <div style={{ color: "red" }}>Please wait, uploading ...</div>
                </div>
              </div>
            )}
            <div className="files_info">
              <img src={"/static/common/info.svg"} alt="" />
              Add maximum 4 photos and 1 video upto 20 MB
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(FilesUploadComponent);

import React from "react";
import { apiConstants } from "../../../utils/Constants";

type Props = {
  [key: string]: any;
};

const SelectPayoutButton = ({
  type,
  getValues,
  handleClick = () => null,
}: Props) => {
  switch (type) {
    case "stripe":
      return (
        <div className="select_payout_btn" onClick={handleClick}>
          <div className="select_payout_heading">
            Payment Mode - Through Stripe
          </div>
          <div className="d-flex flex-column gap-1 mt-2">
            <div className="select_payout_details special">
              {`Stripe Number: ${getValues(apiConstants.stripe_account_id)}`}
            </div>
          </div>
        </div>
      );

    case "direct":
      return (
        <div className="select_payout_btn" onClick={handleClick}>
          <div className="select_payout_heading">
            Payment Mode - Direct Bank Transfer
          </div>
          <div className="d-flex flex-column gap-1 mt-2">
            <div className="select_payout_details special">
              {`Account Number: ${getValues(apiConstants.account_number)}`}
            </div>
            <div className="select_payout_details">
              {`BSB Number: ${getValues(apiConstants.bsb_number)}`}
            </div>
            <div className="select_payout_details ">
              {`Account Name: ${getValues(apiConstants.bank_name)}`}
            </div>
          </div>
        </div>
      );

    default:
      return (
        <div
          className="select_payout_btn d-flex justify-content-between"
          onClick={handleClick}
        >
          <div className="select_payout_heading">Select payment method</div>
          <img src="/static/createListingIcon/forwardGrey.svg" alt="" />
        </div>
      );
  }
};

export default SelectPayoutButton;

import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import { HEADINGS } from "../../../utils/StringConstant";
import { difficultyLevelPreferenceNew } from "../../../utils/Constants";

const DifficultyLevelSection = React.memo(
  ({
    control,
    setValue,
    handleDifficulty,
    selectedPreferencesDifficulty,
  }: {
    control: any;
    setValue: (field: string, value: any) => void;
    handleDifficulty: (value: string) => void;
    selectedPreferencesDifficulty: string[];
  }) => {
    const renderDifficultyCheckboxes = useMemo(
      () =>
        difficultyLevelPreferenceNew.map((level) => (
          <div key={level.value} className="form_checkbox_button_with_check">
            <Controller
              name={`difficulty_level.${level.value}`}
              control={control}
              render={({ field }) => (
                <div className="form_group">
                  <input
                    {...field}
                    type="checkbox"
                    id={level.title}
                    value={level.value}
                    checked={selectedPreferencesDifficulty.includes(
                      level.value
                    )}
                    onChange={(e) => {
                      const value = e.target.value;
                      const updatedDifficulty = e.target.checked
                        ? [...selectedPreferencesDifficulty, value]
                        : selectedPreferencesDifficulty.filter(
                            (pref) => pref !== value
                          );

                      setValue("difficulty_level", updatedDifficulty);
                    }}
                    onClick={(e: any) => handleDifficulty(e.target.value)}
                  />
                  <label
                    htmlFor={level.title}
                    style={{ justifyContent: "normal" }}
                    className={
                      selectedPreferencesDifficulty.includes(level.value)
                        ? "checked"
                        : "checkbox-gender-label"
                    }
                  >
                    <img
                      src={
                        selectedPreferencesDifficulty.includes(level.value)
                          ? level.activeImg
                          : level.img
                      }
                      style={{
                        background: "#ffffff",
                        padding: "8px 8.5px",
                        borderRadius: "50%",
                      }}
                      alt=""
                    />
                    <span>{level.title}</span>
                    {selectedPreferencesDifficulty.includes(level.value) && (
                      <img
                        src="/static/checkmark-circle.svg"
                        className="checkmark-circle"
                        alt=""
                      />
                    )}
                  </label>
                </div>
              )}
            />
          </div>
        )),
      [
        difficultyLevelPreferenceNew,
        selectedPreferencesDifficulty,
        control,
        setValue,
        handleDifficulty,
      ]
    );

    return (
      <>
        <div
          className="main-had"
          style={{
            marginTop: "18px",
          }}
        >
          <h3 className="headis">{HEADINGS.DIFFICULTY_LEVEL}</h3>
        </div>
        <div className="all-checkbox-button_with-check">
          {renderDifficultyCheckboxes}
        </div>
      </>
    );
  }
);

export default DifficultyLevelSection;

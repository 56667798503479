import React, { useMemo } from "react";
import { apiConstants } from "../../../utils/Constants";
import { LISTING_TYPE } from "../../../types";
import { HEADINGS, STRING_CONSTANTS } from "../../../utils/StringConstant";
import { OutlineYellowButton } from "../../../global/OutlineButton";
import AddIconYellow from "../../../assets/img/icons/AddIconYellow";
import InfoMessage from "../InfoMessage";
import EventCardSection from "../EventsCardSection/EventCardSection";

interface PackageEventSectionProps {
  watch: (field: string) => any;
  getValues: (field: string) => any;
  onDeleteEvent: (eventId: string) => void;
  handleEventEditClick: (eventId: string) => void;
  setShowAddEventSidebar: (value: boolean) => void;
  setShowEventListingsSidebar: (value: boolean) => void;
  toggleAddEventInfo: boolean;
  setToggleAddEventInfo: (value: boolean) => void;
}

const PackageEventSection: React.FC<PackageEventSectionProps> = React.memo(
  ({
    watch,
    getValues,
    onDeleteEvent,
    handleEventEditClick,
    setShowAddEventSidebar,
    setShowEventListingsSidebar,
    toggleAddEventInfo,
    setToggleAddEventInfo,
  }) => {
    const listingType = watch(apiConstants.listingType);
    const multipleEvents = getValues(apiConstants.multipleEvents);

    const eventCardSection = useMemo(() => {
      if (multipleEvents?.length > 0) {
        return (
          <EventCardSection
            data={multipleEvents.slice(0, 2)}
            onDeleteEvent={onDeleteEvent}
            handleEventEditClick={handleEventEditClick}
            setShowEventListingsSidebar={setShowEventListingsSidebar}
            totalData={multipleEvents.length}
            watch={watch}
          />
        );
      }
      return null;
    }, [
      multipleEvents,
      onDeleteEvent,
      handleEventEditClick,
      setShowEventListingsSidebar,
      watch,
    ]);

    const addEventButton = useMemo(() => {
      return (
        <OutlineYellowButton
          style={{ border: "1px solid #8f939a" }}
          text={HEADINGS.ADD_EVENT}
          icon={<AddIconYellow />}
          onClick={() => {
            setShowEventListingsSidebar(false);
            setShowAddEventSidebar(true);
          }}
        />
      );
    }, [setShowAddEventSidebar, setShowEventListingsSidebar]);

    return (
      <>
        {listingType === LISTING_TYPE.M && (
          <>
            <div className="main-had" style={{ marginTop: "24px" }}>
              <h3 className="headis">{HEADINGS.ADD_EVENT}</h3>
              <img
                src="/static/info.svg"
                alt=""
                className="info_icon"
                onClick={() => setToggleAddEventInfo(!toggleAddEventInfo)}
              />
            </div>
            {eventCardSection}
            {addEventButton}
          </>
        )}
        {toggleAddEventInfo && (
          <InfoMessage
            message={STRING_CONSTANTS.listing_info}
            style={{ marginTop: "12px" }}
          />
        )}
      </>
    );
  }
);

export default PackageEventSection;

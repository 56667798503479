import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import { apiConstants } from "../../../utils/Constants";
import { LABELS, STRING_CONSTANTS } from "../../../utils/StringConstant";
import { GendersTypeSpecific } from "../../../types";

const GenderPreferenceSection = React.memo(
  ({
    watch,
    handleSetValue,
    control,
    handleGender,
  }: {
    watch: (field: string) => any;
    handleSetValue: (field: string, value: any) => void;
    control: any;
    handleGender: (value: string) => void;
  }) => {
    const selectedGender = watch(apiConstants.selectedGender);

    const isAllGendersSelected = useMemo(
      () => selectedGender?.length === 3,
      [selectedGender]
    );

    const radioButtons = useMemo(() => {
      return [
        {
          checked: isAllGendersSelected,
          label: LABELS.LISTING_FOR_ALL_GENDERS,
          onClick: () =>
            handleSetValue(apiConstants.selectedGender, ["M", "F", "N"]),
        },
        {
          checked: !isAllGendersSelected,
          label: LABELS.LISTING_FOR_SPECIFIC_GENDER,
          onClick: () => handleSetValue(apiConstants.selectedGender, []),
        },
      ];
    }, [isAllGendersSelected, handleSetValue]);

    return (
      <>
        <div className="main-had" style={{ marginTop: "0px" }}>
          <h3 className="headis">{STRING_CONSTANTS.gender_preference}</h3>
        </div>
        <div
          className="mb-2"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {radioButtons.map((radio, index) => (
            <div key={index} className="listing_type_radio">
              <img
                src={
                  radio.checked
                    ? "/static/radiocheck.svg"
                    : "/static/radiouncheck.svg"
                }
                alt=""
                onClick={radio.onClick}
              />
              <label
                className={`listing_ype_label cursor-pointer ${
                  radio.checked ? "active" : ""
                }`}
                onClick={radio.onClick}
              >
                {radio.label}
              </label>
            </div>
          ))}
        </div>
        {selectedGender?.length < 3 && (
          <div className="all-checkbox-button_with-check m-0">
            {GendersTypeSpecific?.map((g) => (
              <div key={g.value} className="form_checkbox_button_with_check">
                <Controller
                  name={`gender_preference.${g.value}`}
                  control={control}
                  render={({ field }) => (
                    <div className="form_group">
                      <input
                        {...field}
                        type="checkbox"
                        id={g.title}
                        value={g.value}
                        checked={selectedGender.includes(g.value)}
                        onClick={(e: any) => handleGender(e.target.value)}
                      />
                      <label
                        htmlFor={g.title}
                        style={{ justifyContent: "normal" }}
                        className={
                          selectedGender.includes(g.value)
                            ? "checked"
                            : "checkbox-gender-label"
                        }
                      >
                        <img
                          src={g.activeImg}
                          style={{
                            background: "#ffffff",
                            padding: "8px 8.5px",
                            borderRadius: "50%",
                          }}
                          alt=""
                        />
                        <span>{g.title}</span>
                        {selectedGender.includes(g.value) && (
                          <img
                            src="/static/checkmark-circle.svg"
                            className="checkmark-circle"
                            alt=""
                          />
                        )}
                      </label>
                    </div>
                  )}
                />
              </div>
            ))}
          </div>
        )}
      </>
    );
  }
);

export default GenderPreferenceSection;

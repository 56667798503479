import { Navigate, Route, Routes } from "react-router-dom";
import Faq from "../components/Dashboard/Faq";
import PasswordManagement from "../components/Dashboard/PasswordManagement";
import PolicyPage from "../components/Dashboard/PolicyPage";
import TrainingExperienceNew from "../components/Profile/Dashboard/TrainingExperienceNew";
import ComingSoon from "../global/ComingSoon";
import EventsPage from "../pages/EventsPage";
import EventsSearchPage from "../pages/EventsSearchPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import Login from "../pages/Login";
import ManageProfilePage from "../pages/ManageProfilePage";
import PaymentMethodPage from "../pages/PaymentMethodPage";
import PaymentMethodPageApple from "../pages/PaymentMethodPageApple";
import PersonalDetailPage from "../pages/PersonalDetailPage";
import Register from "../pages/Register";
import ViewListingPage from "../pages/ViewListingPage";
import { ROUTE_CONSTANTS } from "../utils/RouteConstants";
import ListingDetail from "../components/Dashboard/ListingDetail";
import DraftsPage from "../pages/DraftsPage";
import ProfileMenuPage from "../pages/ProfileMenuPage";
import BookingEvent from "../components/Dashboard/BookingEvent";
import PromotionPage from "../pages/PromotionPage";
import Earnings from "../components/Dashboard/Earnings";
import EarnBookingPage from "../pages/EarnBookingPage";
import Welcome from "../pages/Welcome";
import PurchaseListing from "../components/Dashboard/PurchaseListing";
import ReviewScreen from "../components/Dashboard/Review";
import Subscription from "../components/Dashboard/Subscription/Subscription";
import ChatPage from "../pages/ChatPage";
import MyBookings from "../components/Dashboard/MyBookings";
import Profile from "../components/UserProfile/Profile";
import MyListingsPage from "../pages/MyListingPage";
import NotificationPage from "../pages/Notification";
import StripeSuccess from "../components/Stripe/StripeSuccess";
import StripeFailed from "../components/Stripe/StripeFailed";
import BookingDetails from "../components/Dashboard/BookingDetails";
import EventBookingPage from "../pages/EventBookingPage";
import ListingDetailTwo from "../components/Dashboard/ListingDetailTwo";
import ListingDetailGallery from "../components/Dashboard/ListingDetailGallery";
import PromotionDetails from "../components/Promotion/PromotionDetails";
import LandingPageNew from "../pages/LandingPage";
import InterestNew from "../components/Profile/Dashboard/IntersetsNew";
import BankAccountStripe from "../components/Profile/Dashboard/BankAccountStripe";
import FavouriteNew from "../components/Profile/Dashboard/FavouriteNew";
import ConnectConnections from "../components/Connect/ConnectConnections";
import ConnectRequests from "../components/Connect/ConnectRequests";
import ConnectSuggestions from "../components/Connect/ConnectSuggestions";
import ConnectFavourites from "../components/Connect/ConnectFavourites";
import ConnectFlagged from "../components/Connect/ConnectFlagged";
import ConnectZone from "../components/Connect/ConnectZone";
import BusinessDetailPage from "../pages/BusinessDetailPage";
import MyTeamListing from "../components/Profile/Dashboard/MyTeamListing";
import TeamListingDetails from "../components/Profile/Dashboard/TeamListingDetails";
import ConnectUserProfile from "../pages/ConnectUserProfile/ConnectUserProfile";
import PaymentClose from "../pages/PaymentClose";
import AddListingPage from "../pages/AddListingPage";
import SelectListingTypePage from "../pages/SelectListingTypePage";
import ModifyListingPage from "../pages/ModifyListingPage";
import MemberType from "../components/Profile/MemberType";
import ProtectedRoutes from "./ProtectedRoutes";
import OnboardingRoutes from "./OnboardingRoutes";
import ConnectWrapper from "../components/Connect/ConnectComponents/ConectWrapper";
import SpecialRoutes from "./SpecialRoutes";

const routes = [
  { path: ROUTE_CONSTANTS.landing, element: <EventsPage />, type: "special" },
  {
    path: ROUTE_CONSTANTS.search,
    element: <EventsSearchPage />,
    type: "public",
  },
  { path: ROUTE_CONSTANTS.policies, element: <PolicyPage />, type: "public" },
  {
    path: ROUTE_CONSTANTS.about_us,
    element: <LandingPageNew />,
    type: "public",
  },
  {
    path: ROUTE_CONSTANTS.dashboard,
    element: <Navigate to={ROUTE_CONSTANTS.landing} />,
    type: "public",
  },
  { path: ROUTE_CONSTANTS.register, element: <Register />, type: "public" },
  {
    path: ROUTE_CONSTANTS.forgot_password,
    element: <ForgotPasswordPage />,
    type: "public",
  },
  { path: ROUTE_CONSTANTS.login, element: <Login />, type: "public" },
  {
    path: ROUTE_CONSTANTS.events,
    element: <Navigate to={ROUTE_CONSTANTS.landing} />,
    type: "public",
  },
  { path: "events/:id", element: <ListingDetail />, type: "public" },
  {
    path: "/listing-two/:id",
    element: <ListingDetailTwo />,
    type: "public",
  },
  {
    path: "/listing-gallery/:id",
    element: <ListingDetailGallery />,
    type: "public",
  },

  { path: ROUTE_CONSTANTS.welcome, element: <Welcome />, type: "onboarding" },
  {
    path: ROUTE_CONSTANTS.select_member_type,
    element: <MemberType />,
    type: "onboarding",
  },
  {
    path: ROUTE_CONSTANTS.manage_profile,
    element: <ManageProfilePage />,
    type: "onboarding",
  },
  {
    path: `${ROUTE_CONSTANTS.events_booking}/:id`,
    element: <EventBookingPage />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.my_created_listing,
    element: <MyListingsPage />,
    type: "protected",
  },
  { path: "events/:id/review", element: <ReviewScreen />, type: "protected" },

  {
    path: ROUTE_CONSTANTS.bank_accounts,
    element: <BankAccountStripe />,
    type: "protected",
  },
  {
    path: "/our-mates/:id",
    element: <ConnectUserProfile />,
    type: "protected",
  },
  { path: "/payment-status", element: <PaymentClose />, type: "public" },
  {
    path: ROUTE_CONSTANTS.personal_details,
    element: <PersonalDetailPage />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.business_details,
    element: <BusinessDetailPage />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.profile_menu,
    element: <ProfileMenuPage />,
    type: "protected",
  },
  { path: `/user/:id`, element: <Profile />, type: "protected" },
  {
    path: ROUTE_CONSTANTS.intrest,
    element: <InterestNew />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.my_earning,
    element: <Earnings />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.favourites,
    element: <FavouriteNew />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.experience,
    element: <TrainingExperienceNew />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.password_management,
    element: <PasswordManagement />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.subscription,
    element: <Subscription />,
    type: "protected",
  },
  { path: ROUTE_CONSTANTS.faq, element: <Faq />, type: "protected" },
  {
    path: ROUTE_CONSTANTS.payment_methods,
    element: <PaymentMethodPage />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.apple_pay,
    element: <PaymentMethodPageApple />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.view_listing,
    element: <ViewListingPage />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.stripeconnect_success,
    element: <StripeSuccess />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.stripeconnect_failed,
    element: <StripeFailed />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.promotion,
    element: <PromotionPage />,
    type: "protected",
  },
  {
    path: `${ROUTE_CONSTANTS.promotion}/:id`,
    element: <PromotionDetails />,
    type: "protected",
  },
  { path: ROUTE_CONSTANTS.drafts, element: <DraftsPage />, type: "protected" },
  {
    path: `${ROUTE_CONSTANTS.bookingEvent}/:event_id/:booking_id`,
    element: <BookingEvent />,
    type: "protected",
  },
  {
    path: `${ROUTE_CONSTANTS.earn_booking}/:listing_id`,
    element: <EarnBookingPage />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.notifications,
    element: <NotificationPage />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.purchase_listing,
    element: <PurchaseListing />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.team_membership,
    element: <MyTeamListing />,
    type: "protected",
  },
  { path: ROUTE_CONSTANTS.chat, element: <ChatPage />, type: "protected" },
  {
    path: ROUTE_CONSTANTS.my_booking,
    element: <MyBookings />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.booking_details,
    element: <BookingDetails />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.team_listing_details,
    element: <TeamListingDetails />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.select_listing,
    element: <SelectListingTypePage />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.new_listing,
    element: <AddListingPage />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.modify_listing,
    element: <ModifyListingPage />,
    type: "protected",
  },
  //connect
  {
    path: ROUTE_CONSTANTS.connect,
    element: (
      <ConnectWrapper>
        <ConnectZone />
      </ConnectWrapper>
    ),
    type: "protected",
  },
  {
    path: "/connect/connections",
    element: (
      <ConnectWrapper>
        <ConnectConnections />
      </ConnectWrapper>
    ),
    type: "protected",
  },
  {
    path: "/connect/requests",
    element: (
      <ConnectWrapper>
        <ConnectRequests />
      </ConnectWrapper>
    ),
    type: "protected",
  },
  {
    path: "/connect/flagged",
    element: (
      <ConnectWrapper>
        <ConnectFlagged />
      </ConnectWrapper>
    ),
    type: "protected",
  },
  {
    path: "/connect/suggestions",
    element: (
      <ConnectWrapper>
        <ConnectSuggestions />
      </ConnectWrapper>
    ),
    type: "protected",
  },
  {
    path: "/connect/favourite",
    element: (
      <ConnectWrapper>
        <ConnectFavourites />
      </ConnectWrapper>
    ),
    type: "protected",
  },
];

const RouteStack = () => {

  return (
    <Routes>
      {routes.map(({ path, element, type }) => (
        <Route
          key={path}
          path={path}
          element={
            type === "protected" ? (
              <ProtectedRoutes>{element}</ProtectedRoutes>
            ) : type === "onboarding" ? (
              <OnboardingRoutes>{element}</OnboardingRoutes>
            ) : type === "special" ? (
              <SpecialRoutes>{element}</SpecialRoutes>
            ) : (
              element
            )
          }
        />
      ))}
      <Route path="*" element={<ComingSoon />} />
      
    </Routes>
  );
};

export default RouteStack;

import React, { useMemo } from "react";
import Button from "../../../global/Button";
import { BUTTON_TEXT } from "../../../utils/StringConstant";

const ListingAction = ({
  getDisableData,
  loadingState,
  handleSaveAsDraft,
  listingDraftData,
  handlePublishEvent,
}) => {
  const draftButton = useMemo(() => {
    return (
      <div className="d-flex gap-2 flex-column flex-sm-row">
        {listingDraftData?.isDraft && (
          <Button
            btnTxt={BUTTON_TEXT.SAVE_DRAFT}
            style={{
              background: getDisableData() ? "#d9d9d9" : "#ed772e3e",
              color: "#ed772e",
              border: "1px solid #ed772e",
            }}
            onClick={handleSaveAsDraft}
            isLoading={loadingState?.isDraft}
            disabled={getDisableData()}
          />
        )}
        <Button
          btnTxt={BUTTON_TEXT.PUBLISH}
          onClick={handlePublishEvent}
          isLoading={loadingState.isCreate}
        />
      </div>
    );
  }, [
    listingDraftData?.isDraft,
    getDisableData,
    handleSaveAsDraft,
    loadingState?.isDraft,
    loadingState.isCreate,
    handlePublishEvent,
  ]);

  return draftButton;
};

export default ListingAction;

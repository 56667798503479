import React, { useState } from "react";
import { Controller, useController } from "react-hook-form";
import ReactInputMask from "react-input-mask";
interface MobileInputProps {
  name: string;
  label?: string;
  style?: React.CSSProperties;
  className?: string;
  errors?: object | any;
  id?: string;
  control?: any;
  minDate?: any;
  maxDate?: any;
  showMonthYearPicker?: boolean;
  dateFormat?: string;
  watch?: any;
  setValue?: any;
  mask?: string;
  inputLabel?: string;
  placeholder?: string;
  displayErrorOutlined?: any;
  maxLength?: any;
}
const MobileInput = (props: MobileInputProps) => {
  const {
    name,
    watch,
    control,
    className,
    errors,
    mask,
    placeholder,
    inputLabel,
    displayErrorOutlined = false,
    maxLength = ''
  } = props;
  const {
    field: { onChange },
  } = useController({
    name,
    control,
  });
  const [isFocus,setIsFocus] = useState(false);
  const handleOnFocus = () => {
    setIsFocus(true);
  };
  const handleOnBlur = () => {
    setIsFocus(false);
  };
  return (
    <Controller
      control={control}
      name={name}
      render={() => {
        return (
          <>
            <div className="form_group">
              <div
                // className={className ? `${className} input_box` : "input_box"}
                className={` input_box  ${watch(name) || ( watch(name) ||isFocus ) ? "focused" : ""} ${
                  className ? className : ""
                } `}
              >
                <span className="input_label">
                  {inputLabel ? inputLabel : "Contact Number"}
                </span>
                <ReactInputMask
                  mask={mask || "99999 99999"}
                  maskChar={null}
                  value={watch(name)}
                  onChange={onChange}
                  placeholder={(isFocus && placeholder) ? placeholder : ''}
                  style={{
                    border: displayErrorOutlined
                      ? "1px solid #FF3B30"
                      : "1px solid #dcdcdc",
                  }}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                  maxLength={maxLength}
                />
                {errors && errors[name] && (
                  <span className="text-danger">{errors[name]?.message}</span>
                )}
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

export default MobileInput;

import React, { useMemo } from "react";
import { LISTING_TYPE } from "../../../types";
import { HEADINGS, STRING_CONSTANTS } from "../../../utils/StringConstant";
import { apiConstants } from "../../../utils/Constants";
import InputField from "../../../global/InputField";
import InfoMessage from "../InfoMessage";

const ListingDetailsForm = ({
  watch,
  control,
  errors,
  setValue,
  getValues,
  isListingInfo,
  setIsListingInfo,
}) => {
  const formContent = useMemo(() => {
    const listingType = watch(apiConstants.listingType);
    const headingText =
      listingType === LISTING_TYPE.S || listingType === LISTING_TYPE.M
        ? STRING_CONSTANTS.listing_details
        : STRING_CONSTANTS.team_details;

    const descriptionLabel =
      listingType === LISTING_TYPE.C
        ? HEADINGS.TEAM_DESCRIPTION_BENEFITS
        : HEADINGS.LISTING_DESCRIPTION;

    return (
      <>
        <div className="main-heaidng">
          <h6>{headingText}</h6>
          <img
            src="/static/info.svg"
            alt="Info"
            onClick={() => setIsListingInfo(!isListingInfo)}
          />
        </div>

        <div style={{ marginBottom: "18px" }}>
          <InputField
            type="text"
            className="floating_input"
            name={apiConstants.listing_name}
            inputLabel="Listing Name"
            placeholder="Name your listing"
            control={control}
            errors={errors}
            setValue={setValue}
          />
        </div>

        <div style={{ marginBottom: "18px" }}>
          <InputField
            isListingTextArea={true}
            type="textarea"
            name={apiConstants.listing_description}
            inputLabel={descriptionLabel}
            className="floating_input"
            placeholder="Tell others more about this listing"
            control={control}
            maxlength="300"
            getValues={getValues}
            errors={errors}
            setValue={setValue}
            onTextAreaInput={(e) => {
              e.target.style.height = "auto"; // Reset height
              e.target.style.height = `${e.target.scrollHeight}px`; // Adjust height
            }}
            textAreaStyle={{
              overflow: "hidden",
              resize: "none", // Prevent manual resize
            }}
          />
          {isListingInfo && (
            <InfoMessage message={STRING_CONSTANTS.listing_info} />
          )}
        </div>
      </>
    );
  }, [
    watch(apiConstants.listingType),
    isListingInfo,
    apiConstants.listing_name,
    apiConstants.listing_description,
    control,
    errors,
    setValue,
    getValues,
  ]);

  return formContent;
};

export default ListingDetailsForm;

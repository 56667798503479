import React, {useEffect, useState} from "react";
import { INTEREST_TYPE } from "../../../utils/Constants";
import useDebounce from "../../../utils/useDebounce";
import {showToast} from "../../../utils";
import {makeGetCall} from "../../../utils/Requests";
import apiConfig from "../../../utils/endpoints";
import CardView from "../../../assets/img/icons/CardView";
import ListView from "../../../assets/img/icons/ListView";
import Button from "../../../global/Button";
import LoadingLayer from "../../ConnectUserProfile/LoaderLayer";
import InterestView from "./InterestView";
import "./style.scss";
import { BUTTON_TEXT } from "../../../utils/StringConstant";

type Props = {
  [key: string]: any;
};

const SelectInterestComponent = (props: Props) => {
  const {interestPicked, handleAdd} = props;
  const [isLoading, setIsLoading] = useState<any>(true);
  const [searchData, setSearchData] = useState("");
  const debouncedSearchTerm = useDebounce(searchData, 500);
  const [selectedInterest, setSelectedInterest] = useState<any>(interestPicked);
  const [isListView, setIsListView] = useState(false);
  const [filter, setFilter] = useState(INTEREST_TYPE.ALL);
  const [interestList, setInterestList] = useState([]);
  const getInterestList = () => {
    setIsLoading(true);
    let params = {want_paginated: false};
    if (filter === INTEREST_TYPE.FEATURED) {
      params["featured"] = true;
    }
    if (searchData) {
      params["search"] = searchData;
    }

    makeGetCall({url: apiConfig.interest_list, params})
      .then((res) => {
        setInterestList(res?.data);

        setTimeout(() => {
          setIsLoading(false);
        }, 0);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };

  useEffect(() => {
    getInterestList();
  }, [debouncedSearchTerm, filter]);

  const handleFilterData = (type = "all") => {
    setFilter(type);
  };

  const handleInterest = (intrest: any) => {
    let index = selectedInterest.findIndex(
      (intr: any) => intr.id === intrest.id
    );
    if (index > -1) {
      selectedInterest.splice(index, 1);
    } else {
      selectedInterest.push(intrest);
    }

    setSelectedInterest([...selectedInterest]);
  };

  const handleNext = () => handleAdd(selectedInterest);

  return (
    <div className="interest_container_full">
      {isLoading && <LoadingLayer />}
      <div className="heading_listing_interest">
        Add Interest for your listing
      </div>
      <div className="bi_wrapper">
        <div className="bi_container">
          <div className="search_header">
            <div className="search_input_container">
              <div className="search_input_wrapper">
                <div className="search_label">Search Interests</div>
                <input
                  className="input_search_text"
                  type="text"
                  value={searchData}
                  placeholder="Search your interests"
                  onChange={(e) => {
                    setSearchData(e.target.value);
                  }}
                />
                {searchData && (
                  <img
                    src="/static/cross-circle-gray-dark.svg"
                    className="clear_data"
                    alt=""
                    onClick={() => setSearchData("")}
                  />
                )}
              </div>
            </div>
            <div className="search_view">
              <CardView
                active={!isListView}
                className="cursor-pointer"
                onClick={() => setIsListView(false)}
              />
              <ListView
                active={isListView}
                className="cursor-pointer"
                onClick={() => setIsListView(true)}
              />
            </div>
          </div>
          <div className="filter_container">
            <Button
              btnTxt={BUTTON_TEXT.ALL}
              onClick={() => {
                handleFilterData(INTEREST_TYPE.ALL);
              }}
              className={`all_filter_btn ${
                filter !== INTEREST_TYPE.ALL ? "inactive" : ""
              }`}
            />
            <Button
              btnTxt={BUTTON_TEXT.FEATURED}
              onClick={() => {
                handleFilterData(INTEREST_TYPE.FEATURED);
              }}
              className={`all_filter_btn ${
                filter !== INTEREST_TYPE.FEATURED ? "inactive" : ""
              }`}
            />
          </div>
          <div className="body_interest">
            {interestList?.length < 1 && (
              <div style={{ textAlign: "center" }}>No interest found!</div>
            )}
            <InterestView
              isListView={isListView}
              interestList={interestList}
              selectedInterest={selectedInterest}
              handleInterest={handleInterest}
            />
          </div>
          <div className="btn_footer">
            <Button
              style={{ maxWidth: "550px" }}
              btnTxt={BUTTON_TEXT.ADD}
              className={"btn_continue"}
              isLoading={isLoading}
              onClick={handleNext}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SelectInterestComponent);

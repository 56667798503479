import React, { useMemo } from "react";
import { apiConstants } from "../../../utils/Constants";
import { PAYOUT_METHOD, USER_TYPE } from "../../../types";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import InfoMessage from "../InfoMessage";
import SelectPayoutButton from "../SelectPayoutButton";

const PayoutSection = React.memo(
  ({
    userType,
    watch,
    getValues,
    isMediaUploadInprogress,
    showMediaUploadInProgressWarning,
    handleScreenSection,
    togglePaymentHowInfo,
    setTogglePaymentHowInfo,
  }: {
    userType: string;
    watch: (field: string) => any;
    getValues: (field: string) => any;
    isMediaUploadInprogress: boolean;
    showMediaUploadInProgressWarning: () => void;
    handleScreenSection: (section: string) => void;
    togglePaymentHowInfo: boolean;
    setTogglePaymentHowInfo: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const isGuestUser = userType === USER_TYPE.GUEST;
    const listingType = watch(apiConstants.listingType);
    const paymentMode = watch(apiConstants.payment_mode);
    const stripeAccountId = watch(apiConstants.stripe_account_id);
    const bsbNumber = watch(apiConstants.bsb_number);

    const shouldShowPayoutSection = useMemo(() => {
      return !isGuestUser && listingType !== "C";
    }, [isGuestUser, listingType]);

    const payoutType = useMemo(() => {
      if (paymentMode === PAYOUT_METHOD.STRIPE && stripeAccountId) {
        return "stripe";
      }
      if (paymentMode === PAYOUT_METHOD.BANK && bsbNumber) {
        return "direct";
      }
      return "";
    }, [paymentMode, stripeAccountId, bsbNumber]);

    return shouldShowPayoutSection ? (
      <div style={{ marginBottom: "28px", marginTop: "28px" }}>
        <div className="main-had">
          <h3 className="headis">How do you want to receive the payment</h3>
          <img
            src="/static/info.svg"
            alt=""
            className="info_icon"
            onClick={() => setTogglePaymentHowInfo((prev) => !prev)}
          />
        </div>
        <SelectPayoutButton
          type={payoutType}
          getValues={getValues}
          handleClick={() =>
            isMediaUploadInprogress
              ? showMediaUploadInProgressWarning()
              : handleScreenSection("payment")
          }
        />
        {togglePaymentHowInfo && (
          <InfoMessage
            message={STRING_CONSTANTS.listing_cost_method}
            style={{ marginTop: "12px" }}
          />
        )}
      </div>
    ) : null;
  }
);

export default PayoutSection;

const AddIconYellow: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M18.8721 12.998H13.8721V17.998C13.8721 18.2633 13.7667 18.5176 13.5792 18.7052C13.3916 18.8927 13.1373 18.998 12.8721 18.998C12.6069 18.998 12.3525 18.8927 12.165 18.7052C11.9774 18.5176 11.8721 18.2633 11.8721 17.998V12.998H6.87207C6.60685 12.998 6.3525 12.8927 6.16496 12.7052C5.97743 12.5176 5.87207 12.2633 5.87207 11.998C5.87207 11.7328 5.97743 11.4785 6.16496 11.2909C6.3525 11.1034 6.60685 10.998 6.87207 10.998H11.8721V5.99805C11.8721 5.73283 11.9774 5.47848 12.165 5.29094C12.3525 5.1034 12.6069 4.99805 12.8721 4.99805C13.1373 4.99805 13.3916 5.1034 13.5792 5.29094C13.7667 5.47848 13.8721 5.73283 13.8721 5.99805V10.998H18.8721C19.1373 10.998 19.3916 11.1034 19.5792 11.2909C19.7667 11.4785 19.8721 11.7328 19.8721 11.998C19.8721 12.2633 19.7667 12.5176 19.5792 12.7052C19.3916 12.8927 19.1373 12.998 18.8721 12.998Z"
        fill="url(#paint0_linear_680_49699)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_680_49699"
          x1="3.32335"
          y1="2.91629"
          x2="9.51512"
          y2="29.8825"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE7830" />
          <stop offset="1" stopColor="#EE762F" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AddIconYellow;

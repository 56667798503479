import {PaymentRequestButtonElement} from "@stripe/react-stripe-js";
import {useEffect, useState} from "react";
import {useStripe, useElements} from "@stripe/react-stripe-js";
import Loader from "../../../global/Loader";
import endpoints from "../../../utils/endpoints";
import {makePostCall} from "../../../utils/Requests";

export default function CheckoutFormApple(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
    // Step 1:  we created a payment request object ;
    const pr = stripe.paymentRequest({
      country: "AU",
      currency: "aud",
      total: {
        label: "Test amount",
        amount: 1,
      },
      requestPayerName: true,
      requestPayerEmail: false,
    });

    //Step 2: Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {
      console.log("result:canMakePayment::", result, pr);
      if (result) {
        setPaymentRequest(pr);
      }
    });

    
    pr.on("paymentmethod", async (e) => {
      // step 3: if allowed to make payment then get client-secret key from setup intent
      // Note : WE ARE USING SETUP INTENT CLIENT SECRET FOR SAVING APPLE PAY METHOD ID ON STRIPE
      makePostCall({
        url: endpoints.manage_card,
        apiPayload: JSON.stringify({
          paymentMethodType: "card",
          currency: "aud",
        }),
      })
        .then((res) => {
          if (res.status.code === 200) {
            setClientSecret(res.data);
          }
        })
        .catch((err) => {
          console.log("apple pay error", err);
          setMessage(err.message);
          e?.complete("fail");
          return;
        });

    // step 4: After getting client-secret key listen to card payment process;
      // const {error, paymentIntent} = await stripe.confirmCardPayment(
      //   clientSecret,
      //   {payment_method: e?.paymentMethod.id},
      //   {handleActions: false}
      // );

      // uncomment above code if below method does not work with setup intent;
      alert(`payment id : ${e.paymentMethod.id}`);
      const { error } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: e.paymentMethod.id,
      });
      // step 5: if card payment failed close the window and return with payment request event failed;
      if (error) {
        // Show error to your customer (e.g., insufficient funds)
        console.log("stripe error", error);
        e.complete("fail");
        alert(error.message);
        setMessage(error.message);
        return;
      }
      // step 5: if card payment succeeded close the window and return with payment request event success;
      e?.complete("success"); 
      // setMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
    });

  }, [stripe, elements]);

  return (
    <form id="payment-form" onSubmit={() => {}}>
      {paymentRequest && (
        <PaymentRequestButtonElement options={{paymentRequest}} />
      )}
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

import React, { Fragment, useEffect, useState } from "react";
import {
  apiConstants,
  PAYMENT_MODES,
  SELECTED_CARD_TYPE,
  SELECTED_PAYMENT_METHODS,
} from "../../../utils/Constants";
import "../../../assets/scss/stripePage.scss";
import { makeGetCall } from "../../../utils/Requests";
import apiConfig from "../../../utils/endpoints";
import { showToast } from "../../../utils";
import Button from "../../../global/Button";
import PaymentButton from "../../../global/PaymentButton";

import ConfirmStripePopup from "./ConfirmStripePopup";
import AddBankAccountPopup from "./AddBankAccountPopup";
import WaitingStripeNewAccountPopup from "./WaitingStripeNewAccountPopup";
import BankTransferInfo from "../PaymentMethods/BankTransferInfo";
import BankListingCard from "../PaymentMethods/BankListingCard";
import NoBankListing from "../PaymentMethods/NoBankListing";
import StripeListingCard from "../PaymentMethods/StripeListingCard";
import NoStripeAccount from "../PaymentMethods/NoStripeAccount";
import { BUTTON_TEXT, MESSAGE } from "../../../utils/StringConstant";

type Props = {
  setIsPaymentOpen: (value: boolean) => void;
  isRecommend: boolean;
  setIsRecommend: (value: boolean) => void;
  paymentMode?: string;
  setPaymentMode?: any;
  setValue?: any;
  control?: any;
  errors?: any;
  handleConnectStripe?: any;
  watch?: any;
  handleClose?: any;
};

interface ListingData {
  [key: string]: any;
}

const ListingPayout = ({
  paymentMode,
  setPaymentMode,
  setValue,
  control,
  errors,
  handleConnectStripe,
  watch,
  handleClose = () => null,
}: Props) => {
  const [confirmStripeModal, setConfirmStripeModal] = useState(false);
  const [bankDetailsModal, setBankDetailsModal] = useState(false);
  const [waitingNewStripeAccountModal, setWaitingNewStripeAccountModal] =
    useState(false);
  const [listingType, setListingType] = useState("");
  const [method, setMethod] = useState("");
  const [listingData, setListingData] = useState<ListingData>({});
  const [selectedData, setSelectedData] = useState<ListingData>({});
  const [stripeAccount, setStripeAccount] = useState("");

  const handleExisting = async (type) => {
    if (type === SELECTED_PAYMENT_METHODS.BANK) {
      setValue(apiConstants.account_number, "");
      setValue(apiConstants.bsb_number, "");
      setValue(apiConstants.bank_name, "");
    }
    setListingType(type);
    setConfirmStripeModal(false);
  };

  const handleNew = async (type) => {
    setConfirmStripeModal(false);
    if (type === SELECTED_PAYMENT_METHODS.BANK) {
      setBankDetailsModal(true);
    } else {
      handleConnectStripe();
      setWaitingNewStripeAccountModal(true);
    }
  };

  const handleBankDetails = () => {
    if (
      !watch(apiConstants.bsb_number) ||
      !watch(apiConstants.account_number) ||
      !watch(apiConstants.bank_name)
    ) {
      showToast(MESSAGE.FILL_ALL_DETAILS, "error");
    } else if (watch(apiConstants.bsb_number)?.length < 6) {
      showToast(MESSAGE.INVALID_BSB_NUMBER, "error");
    } else if (
      watch(apiConstants.account_number)?.length > 10 ||
      watch(apiConstants.account_number)?.length < 6
    ) {
      showToast(MESSAGE.INVALID_ACCOUNT_NUMBER, "error");
    } else {
      setValue(apiConstants.existing_or_new, SELECTED_CARD_TYPE.NEW);
      setPaymentMode(PAYMENT_MODES.DIRECT_BANK_TRANSFER);
      handleClose();
    }
  };

  const getSavedPayment = () => {
    makeGetCall({ url: apiConfig.saved_payments })
      .then((res) => {
        if (res.status.code === 200) {
          setListingData(res?.data);
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  useEffect(() => {
    getSavedPayment();
  }, []);

  const handleBankSelectContinue = () => {
    setValue(
      apiConstants.account_number,
      selectedData?.directBankAccountNumber
    );
    setValue(apiConstants.bsb_number, selectedData?.directBankBsbNumber);
    setValue(apiConstants.bank_name, selectedData?.directBankAccountName);
    setValue(apiConstants.existing_or_new, SELECTED_CARD_TYPE.EXISTING);
    setPaymentMode(PAYMENT_MODES.DIRECT_BANK_TRANSFER);
    setValue(apiConstants.payment_mode, PAYMENT_MODES.DIRECT_BANK_TRANSFER);
    handleClose();
  };

  const handleStripeSelectContinue = () => {
    setValue(apiConstants.stripe_account_id, selectedData?.id);
    setValue(apiConstants.existing_or_new, SELECTED_CARD_TYPE.EXISTING);
    setValue(apiConstants.payment_mode, PAYMENT_MODES.THROUGH_STRIPE);
    setPaymentMode(PAYMENT_MODES.THROUGH_STRIPE);
    handleClose();
  };

  const handleNewStripeAccount = (id: any) => {
    setValue(apiConstants.stripe_account_id, id);
    setValue(apiConstants.existing_or_new, SELECTED_CARD_TYPE.EXISTING);
    setValue(apiConstants.payment_mode, PAYMENT_MODES.THROUGH_STRIPE);
    setPaymentMode(PAYMENT_MODES.THROUGH_STRIPE);
    handleClose();
  };

  const handleAddStripeAccount = () => {
    setWaitingNewStripeAccountModal(true);
    handleConnectStripe();
  };

  const handleSelectStripeCard = (index, data) => {
    setStripeAccount(index);
    setSelectedData(data);
  };

  const handleBack = () => {
    if (listingType) {
      setListingType("");
    } else {
      handleClose();
    }
  };

  return (
    <div className="user-profile create_listing payment-mode-wrapper create_listing_main wrap_stripe_payment">
      <div className="user-white-part-width" style={{ padding: 0 }}>
        <img
          src="/static/leftarrow.svg"
          alt=""
          className="handle_back_arrow"
          onClick={handleBack}
          style={{ marginTop: "0" }}
        />
        {listingType === SELECTED_PAYMENT_METHODS.STRIPE ? (
          <div className="container_stripe_payment_listing">
            <div className="payment_heading">
              <img
                src="/static/leftarrow.svg"
                alt=""
                className="handle_back_arrow_resp"
                onClick={handleBack}
                style={{ marginTop: "0" }}
              />
              <div className="heading_transfer">Existing Stripe Account</div>
            </div>
            <div className="height_listing">
              {listingData?.stripeDetails?.length > 0 ? (
                <div className="stripe_acc_listing">
                  {listingData?.stripeDetails?.map((data, index) => (
                    <Fragment key={"stripe" + index}>
                      <StripeListingCard
                        data={data}
                        index={index}
                        stripeAccount={stripeAccount}
                        handleSelectStripeCard={handleSelectStripeCard}
                      />
                    </Fragment>
                  ))}
                </div>
              ) : (
                <NoStripeAccount
                  handleAddStripeAccount={handleAddStripeAccount}
                />
              )}
            </div>

            {listingData?.stripeDetails?.length > 0 ? (
              <div className="acc_btn_container">
                <Button
                  btnTxt={BUTTON_TEXT.CONTINUE}
                  className={"stripe_acc_continue"}
                  disabled={Object.keys(selectedData).length < 1}
                  onClick={handleStripeSelectContinue}
                />

                <Button
                  btnTxt={BUTTON_TEXT.DID_NOT_FIND_STRIPE}
                  className={"stripe_acc_new"}
                  onClick={handleConnectStripe}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ) : listingType === SELECTED_PAYMENT_METHODS.BANK ? (
          <div className="container_stripe_payment_listing">
            <div className="payment_heading">
              <img
                src="/static/leftarrow.svg"
                alt=""
                className="handle_back_arrow_resp"
                onClick={handleBack}
                style={{ marginTop: "0" }}
              />
              <div className="heading_transfer">
                {PAYMENT_MODES.DIRECT_BANK_TRANSFER}
              </div>
            </div>
            <BankTransferInfo />
            <div className="height_listing bank_transfer">
              {listingData?.directBankDetails?.length > 0 ? (
                <div className="stripe_acc_listing">
                  {listingData?.directBankDetails?.map((data, index) => (
                    <Fragment key={"bank" + index}>
                      <BankListingCard
                        data={data}
                        index={index}
                        stripeAccount={stripeAccount}
                        handleSelectStripeCard={handleSelectStripeCard}
                      />
                    </Fragment>
                  ))}
                </div>
              ) : (
                <>
                  <NoBankListing setBankDetailsModal={setBankDetailsModal} />
                </>
              )}
            </div>

            {listingData?.directBankDetails?.length > 0 ? (
              <div className="acc_btn_container">
                <Button
                  btnTxt={BUTTON_TEXT.CONTINUE}
                  disabled={Object.keys(selectedData).length < 1}
                  className={"stripe_acc_continue"}
                  onClick={handleBankSelectContinue}
                />

                <Button
                  btnTxt={BUTTON_TEXT.DID_NOT_FIND_BANK}
                  className={"stripe_acc_new"}
                  onClick={() => setBankDetailsModal(true)}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="container_stripe_payment">
            <div className="payment_heading">
              <img
                src="/static/leftarrow.svg"
                alt=""
                className="handle_back_arrow_resp"
                onClick={handleBack}
                style={{ marginTop: "0" }}
              />
              How do you want to receive the payment?
            </div>
            <div className="payment_subheading">
              Please select the preferred payment method for receiving the
              earnings from your organized event.
            </div>

            <div>
              <PaymentButton
                className={
                  paymentMode === PAYMENT_MODES.THROUGH_STRIPE ? "active" : null
                }
                isRecommend
                btnTxt={
                  paymentMode === PAYMENT_MODES.THROUGH_STRIPE
                    ? PAYMENT_MODES.THROUGH_STRIPE
                    : BUTTON_TEXT.CONNECT_STRIPE
                }
                btnImg="/static/stripe-btn.svg"
                onClick={() => {
                  setValue(
                    apiConstants.payment_mode,
                    PAYMENT_MODES.THROUGH_STRIPE
                  );
                  setConfirmStripeModal(true);
                  setMethod(SELECTED_PAYMENT_METHODS.STRIPE);
                }}
              />
            </div>
            <div className="or_hr"> or</div>
            <div>
              <PaymentButton
                className={
                  paymentMode === PAYMENT_MODES.DIRECT_BANK_TRANSFER
                    ? "active"
                    : null
                }
                btnTxt={PAYMENT_MODES.DIRECT_BANK_TRANSFER}
                btnImg="/static/bank-btn.svg"
                onClick={() => {
                  setValue(
                    apiConstants.payment_mode,
                    PAYMENT_MODES.DIRECT_BANK_TRANSFER
                  );
                  setConfirmStripeModal(true);
                  setMethod(SELECTED_PAYMENT_METHODS.BANK);
                }}
              />
            </div>
          </div>
        )}
      </div>

      {confirmStripeModal && (
        <ConfirmStripePopup
          onClose={() => setConfirmStripeModal(false)}
          handleExisting={handleExisting}
          handleNew={handleNew}
          method={method}
        />
      )}
      {bankDetailsModal && (
        <AddBankAccountPopup
          onClose={() => setBankDetailsModal(false)}
          handleBankDetails={handleBankDetails}
          control={control}
          errors={errors}
          setValue={setValue}
          watch={watch}
        />
      )}

      {waitingNewStripeAccountModal && (
        <WaitingStripeNewAccountPopup
          onClose={() => setWaitingNewStripeAccountModal(false)}
          handleNewStripeAccount={handleNewStripeAccount}
        />
      )}
    </div>
  );
};

export default React.memo(ListingPayout);

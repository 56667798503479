import React from "react";

const InterestView = ({
  isListView = false,
  interestList = [],
  selectedInterest = [],
  handleInterest,
}) => {
  return isListView ? (
    <div className="interest_list_wrapper">
      {interestList?.map((val) => (
        <div
          key={val?.id}
          className={`interest_list ${
            selectedInterest.some((intr: any) => intr.id === val.id)
              ? "selected"
              : ""
          }`}
          onClick={() => {handleInterest(val)}}
        >
          <div>
            <img
              className="list_image"
              src={val?.icon || "/static/handball.svg"}
              alt=""
            />
          </div>
          <div className="list_title">{val?.title || ""}</div>
        </div>
      ))}
    </div>
  ) : (
    <div className="interest_data_wrapper">
      {interestList?.map((val) => (
        <div
          key={val?.id}
          className={`interest_cards ${
            selectedInterest.some((intr: any) => intr.id === val.id)
              ? "selected"
              : ""
          }`}
          onClick={() => {handleInterest(val)}}
        >
          <div>
            <img
              className="card_image"
              src={val?.image || "/static/dance.png"}
              alt=""
            />
          </div>
          <div className="card_title">{val?.title || ""}</div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(InterestView);

import React from "react";
import {Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import { LISTING_TYPE } from "../../../types";

const ListingCommissionTable = ({
  listing_cost,
  merchantFee,
  toggleFeeInfoShown,
  setToggleFeeInfoShown,
  commissionAmounts,
  listingType,
}) => {
  const calculatePercentageOfNumber = (percentage: any, number: any) => {
    const value = (percentage / 100) * number;
    const stringValue = value.toString();
    return Number(parseFloat(stringValue).toFixed(2));
  };

  return (
    <>
      <div className="earning-box-wrap" style={{marginTop: "22px"}}>
        <div className="earning-title">Your Earnings per Participant</div>
        <div className="amount_earning">
          {"AUD "}
          {(Number(listing_cost) || 0) -
            calculatePercentageOfNumber(
              Number(merchantFee || 0),
              Number(listing_cost) || 0
            )}
        </div>
        <div className="info_main">
          <div>
            <img alt="" src="/static/smallinfo.svg" />
          </div>
          <div>
            The earnings shown are before the deduction of booking fee. Your
            actual earnings per participant will vary based on the number of
            attendees. For more details on booking fee, please visit the{" "}
            <strong>My Earnings page</strong>. For additional information,
            please review the{" "}
            <Link
              to="/policies?activeTab=terms"
              target="_blank"
              className="link_terms"
            >
              {"Terms & Conditions."}
            </Link>
          </div>
        </div>
        <div className="divider" />
        <div className="fee-box">
          <div>
            <div className="fee-title">Merchant Fee: </div>
            <div className="desc">
              {"AUD "}
              {calculatePercentageOfNumber(merchantFee || 0, listing_cost)}
            </div>
          </div>
        </div>
        <div className="info">
          <span>Note:</span> Your earnings are subjective to deduction of
          booking fee as well as merchant fee, in order to gain more knowledge{" "}
          <Link
            to="#"
            //  target="_blank"
            className="link_terms"
            onClick={() => setToggleFeeInfoShown(!toggleFeeInfoShown)}
          >
            {toggleFeeInfoShown ? "Hide" : "Click here."}
          </Link>
        </div>
        {toggleFeeInfoShown ? (
          <Table responsive borderless bsPrefix="pay-table">
            <thead className="pay-t-header">
              <tr>
                <th className="first">Number of participants</th>
                <th className="second">
                  Platform fixed Fee per booking per person
                </th>
              </tr>
            </thead>
            <tbody>
              {commissionAmounts?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {item.minimumSlots}{" "}
                      {item.minimumSlots === item.maximumSlots
                        ? ""
                        : item.maximumSlots === null
                        ? "plus"
                        : `to ${item.maximumSlots}`}
                    </td>
                    <td>${item.commissionAmount}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          ""
        )}
        {toggleFeeInfoShown ? (
          <div className="fee-box">
            <div>
              <div className="fee-title">Merchant Fee: </div>
              <div className="desc">
                ${calculatePercentageOfNumber(merchantFee || 0, listing_cost)}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {toggleFeeInfoShown ? (
          <>
            <div className="divider" />
            <div className="info">
              <span>Note:</span>
              {listingType === LISTING_TYPE.C
                ? " You will be charged on monthly basis per booking"
                : listingType === LISTING_TYPE.M
                ? "You will be charged per package booking"
                : "You will be charged as per the number of events"}
            </div>
            <div className="terms-info">
              To gain a better understanding of the booking fee charges, please
              review the{" "}
              <Link className="terms-link" to="/policies" target="_blank">
                terms and conditions.
              </Link>
            </div>{" "}
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default React.memo(ListingCommissionTable);

import React from "react";

const BankListingCard = ({ data, index ,stripeAccount, handleSelectStripeCard}) => {
    return (
      <div
        className="acc_wrapper"
        onClick={() => handleSelectStripeCard(index, data)}
      >
        <div className="acc_content">
          <div className="acc_number">
            Account Number: {data?.directBankAccountNumber}
          </div>
          <div className="acc_added">
            BSB Number: {data?.directBankBsbNumber}
          </div>
          <div className="acc_added">
            Account Name: {data?.directBankAccountName}
          </div>
        </div>
        <div>
          <img
            src={
              stripeAccount === index
                ? "/static/radiocheck.svg"
                : "/static/radiouncheck.svg"
            }
            alt=""
          />
        </div>
      </div>
    );
  };

export default React.memo(BankListingCard);   

import React from "react";
import { Link } from "react-router-dom";
import { CANCELLATION_PERIOD, apiConstants } from "../../../utils/Constants";
import { LISTING_TYPE } from "../../../types";

const CancelPolicyText = () => {
  return (
    <div className="terms-cancel-allow">
      Please read{" "}
      <Link
        to="/policies?activeTab=terms"
        target="_blank"
        style={{ color: "#ee762f" }}
      >
        {" "}
        T&C
      </Link>{" "}
      to understand the{" "}
      <Link
        to="/policies?activeTab=privacy"
        target="_blank"
        style={{ color: "#ee762f" }}
      >
        Cancellation Terms{" "}
      </Link>
    </div>
  );
};

const CancelEventField = ({ watch, handleSetValue }) => {
  return (
    <div className="all-radio-button">
      <div className="form_radio_button ">
        <img
          className="cursor-pointer"
          src={
            !watch(apiConstants.isCancellationAllowed)
              ? "/static/radiocheck.svg"
              : "/static/radiouncheck.svg"
          }
          alt=""
          onClick={() => {
            handleSetValue(apiConstants.isCancellationAllowed, false);
            handleSetValue(apiConstants.cancellation_allowed_till_hours, "");
          }}
        />
        <label
          className={
            watch(apiConstants.isCancellationAllowed) === false
              ? "form-check-label cursor-pointer active"
              : "form-check-label cursor-pointer"
          }
          htmlFor="bookingdontallow"
          onClick={() => {
            handleSetValue(apiConstants.isCancellationAllowed, false);
            handleSetValue(apiConstants.cancellation_allowed_till_hours, "");
          }}
        >
          Don’t allow
        </label>
      </div>
      <div className="form_radio_button">
        <img
          className="cursor-pointer"
          src={
            watch(apiConstants.isCancellationAllowed)
              ? "/static/radiocheck.svg"
              : "/static/radiouncheck.svg"
          }
          alt=""
          onClick={() =>
            handleSetValue(apiConstants.isCancellationAllowed, true)
          }
        />
        <label
          className={
            watch(apiConstants.isCancellationAllowed) === true
              ? "form-check-label cursor-pointer active"
              : "form-check-label cursor-pointer"
          }
          htmlFor="bookingallow"
          onClick={() =>
            handleSetValue(apiConstants.isCancellationAllowed, true)
          }
        >
          Allow
        </label>
      </div>
      {watch(apiConstants.cancellation_allowed_till_hours) ? (
        <div className="booking-chip">
          Allowed till {watch(apiConstants.cancellation_allowed_till_hours)}{" "}
          hours
          <span
            onClick={() => {
              handleSetValue(apiConstants.cancellation_allowed_till_hours, "");
            }}
          >
            <img alt="" src="/static/dismisscircle.svg" />
          </span>
        </div>
      ) : null}
      {watch(apiConstants.listingType) !== LISTING_TYPE.C &&
      watch(apiConstants.isCancellationAllowed) === true ? (
        <div className="form_group">
          <div className="select-box-booking">
            <span className="input_label">Allow cancellation till</span>
            <select
              value={watch(apiConstants.cancellation_allowed_till_hours)}
              onChange={(e) => {
                handleSetValue(
                  apiConstants.cancellation_allowed_till_hours,
                  e.target.value
                );
              }}
              className="select-wrap"
            >
              {CANCELLATION_PERIOD.map((option: any, index) => (
                <option
                  key={index + option}
                  value={option}
                  disabled={option === ""}
                >
                  {option !== ""
                    ? `Allow till ${option} hours`
                    : "select an option"}
                </option>
              ))}
            </select>
            <img
              src={
                watch(apiConstants.cancellation_allowed_till_hours)
                  ? "/static/dismisscircle.svg"
                  : "/static/cross-circle-gray.svg"
              }
              className="clear-icon"
              alt=""
              onClick={() => {
                handleSetValue(
                  apiConstants.cancellation_allowed_till_hours,
                  ""
                );
              }}
            />
          </div>
        </div>
      ) : null}
      <CancelPolicyText />
    </div>
  );
};

export default React.memo(CancelEventField);

export const ROUTE_CONSTANTS = {
  dashboard: "/dashboard",
  personal_details: "/personal-details",
  business_details: "/business-details",
  manage_profile: "/manage-profile",
  business_intrest: "/business-interest",
  manage_expertise: "/manage-expertise",
  upload_document: "/upload-documents",
  intrest: "/interest",
  favourites: "/favourites",
  experience: "/experience",
  qualification: "/qualification",
  payment_methods: "/payment-methods",
  apple_pay:"/test-apple-pay",
  password_management: "/password-management",
  subscription: "/subscription",
  policies: "/policies",
  faq: "/faq",
  contact: "/contact",
  delete_account: "/delete-account",
  register: "/register",
  forgot_password: "/forgot-password",
  login: "/login",
  policy: "/policies",
  create_listing: "/select-listing",
  events: "/events",
  view_listing: "/view-listing",
  edit_listing: "/edit-listing-old",
  drafts: "/drafts",
  profile_menu: "/profile-menu",
  landing: "/",
  search: "/search",
  bookingEvent: "/booking",
  promotion: "/promotions",
  my_earning: "/earnings",
  earn_booking: "/earn-booking",
  welcome: "/welcome",
  listing_block: "/listing-block",
  purchase_listing: "/purchase-listing",
  team_membership: "/team-membership",
  user_profile: "/user-profile",
  connect: "/connect",
  connect_connections: "/connect-connections",
  connect_request: "/connect-requests",
  connect_suggestions: "/connect-suggestion",
  connect_favourites: "/connect-favourites",
  connect_flagged: "/connect-flagged",
  chat: "/chat",
  my_booking: "/my-booking",
  my_created_listing: "/my-listings",
  notifications: "/notifications",
  stripeconnect_success: "/stripeconnect/success",
  stripeconnect_failed: "/stripeconnect/failed",
  joining: "/join",
  events_booking: "/events-booking",
  booking_details: "/view-details/:bookingId",
  team_listing_details: "/view-team-details/:bookingId",
  about_us: "/about-us",
  bank_accounts: "/payments-types",
  select_member_type:"/select-member-type",

  // new-work
  new_listing: "/new-listing/:type",
  new_listing_path: "/new-listing/",
  select_listing: "/select-listing",
  modify_listing: "/edit-listing/:listingId",
};

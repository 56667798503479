import React from "react";
import Button from "../../../global/Button";

const NoStripAccount = ({handleAddStripeAccount}) => {
  return (
    <div className="no_listing">
      <div>
        <img src="/static/cardWhite.svg" alt="" />
      </div>

      <div className="no_listing_desc">
        No stripe accounts added here, <br /> click on add button
      </div>
      <div>
        <Button
          btnTxt="Add"
          className={"stripe_acc_add"}
          onClick={() => handleAddStripeAccount()}
        />
      </div>
    </div>
  );
};

export default React.memo(NoStripAccount);

import React from "react";
import { LISTING_TYPE } from "../../../types";
import { apiConstants } from "../../../utils/Constants";
import { handleShowScheduleDate } from "../../../constants/helperCreateListing";

interface SessionScheduleSectionProps {
  watch: (field: string) => any;
  isMediaUploadInprogress: boolean;
  showMediaUploadInProgressWarning: any;
  handleScheduleListing: any;
  sessionScheduleData: any;
}

const SessionScheduleSection = React.memo(
  ({
    watch,
    isMediaUploadInprogress,
    showMediaUploadInProgressWarning,
    handleScheduleListing,
    sessionScheduleData,
  }: SessionScheduleSectionProps) => {
    const shouldRender = watch(apiConstants.listingType) === LISTING_TYPE.S;

    return shouldRender ? (
      <div
        className="form_group with-button"
        style={{
          marginTop: "28px",
          cursor: "pointer",
        }}
      >
        <div className="input_box">
          <span className="input_label">Schedule Dates</span>
          <div
            className="schedule_date_input"
            onClick={
              isMediaUploadInprogress
                ? showMediaUploadInProgressWarning
                : handleScheduleListing
            }
          >
            {handleShowScheduleDate(sessionScheduleData)}
          </div>
        </div>
      </div>
    ) : null;
  }
);

export default SessionScheduleSection;

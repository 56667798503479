import React from "react";
import moment from "moment";

const StripeListingCard = ({ data, index  ,stripeAccount,  handleSelectStripeCard}) => {
    return (
      <div
        className="acc_wrapper"
        onClick={() => handleSelectStripeCard(index, data)}
      >
        <div className="acc_content">
          <div className="acc_number">
            Stripe number: {data?.stripeAccountId}
          </div>
          <div className="acc_added">
            Added on: {moment(data?.created_at).format("Do MMMM YYYY")}
          </div>
        </div>
        <div>
          <img
            src={
              stripeAccount === index
                ? "/static/radiocheck.svg"
                : "/static/radiouncheck.svg"
            }
            alt=""
          />
        </div>
      </div>
    );
  };

export default React.memo(StripeListingCard);

import React from "react";
import { ACTIVITY_TYPE, apiConstants } from "../../../utils/Constants";
import { LISTING_TYPE } from "../../../types";
import LocationSection from "./LocationSection";

interface AddLocationSectionProps {
  watch: (field: string) => any;
  setIsLocationAccessible: (value: boolean) => void;
  isLocationAccessible: boolean;
  handleLocation: (
    lat: string,
    lng: string,
    address: string,
    address_components: any
  ) => void;
  handleSetValue: (field: string, value: any) => void;
  setValue: (field: string, value: any) => void;
  control: any;
  setLocationData: (data: any) => void;
}

const AddLocationSection: React.FC<AddLocationSectionProps> = React.memo(
  ({
    watch,
    setIsLocationAccessible,
    isLocationAccessible,
    handleLocation,
    handleSetValue,
    setValue,
    control,
    setLocationData,
  }) => {
    const shouldRenderLocationSection =
      watch(apiConstants.listing_event_type) === ACTIVITY_TYPE.PHYSICAL &&
      watch(apiConstants.listingType) !== LISTING_TYPE.M;

    return (
      <>
        {shouldRenderLocationSection && (
          <div style={{ marginTop: "24px" }}>
            <LocationSection
              watch={watch}
              setIsLocationAccessible={setIsLocationAccessible}
              setShowLocationPopup={() => null}
              isLocationAccessible={isLocationAccessible}
              handleLocation={handleLocation}
              handleSetValue={handleSetValue}
              setValue={setValue}
              control={control}
              errors={{}}
              setLocationData={setLocationData}
            />
          </div>
        )}
      </>
    );
  }
);

export default AddLocationSection;

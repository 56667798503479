import React from "react";
import Button from "../../../global/Button";

const NoBankListing = ({setBankDetailsModal}) => {
    return (
      <div className="no_listing">
        <div>
          <img src="/static/cardWhite.svg" alt="" />
        </div>
        <div className="no_listing_desc">
          No bank details added here, <br /> click on add button
        </div>
        <div>
          <Button
            btnTxt="Add"
            className={"stripe_acc_add"}
            onClick={() => setBankDetailsModal(true)}
          />
        </div>
      </div>
    );
  };

  export default React.memo(NoBankListing);
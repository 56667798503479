import React, { useMemo } from "react";
import Button from "../../../global/Button";
import { BUTTON_TEXT } from "../../../utils/StringConstant";

const StickyDraftButton = ({
  getDisableData,
  loadingState,
  handleSaveAsDraft,
}) => {
  const draftButton = useMemo(() => {
    return (
      <div className="d-flex justify-content-end">
        <Button
          btnImg="/static/savebtn.svg"
          btnTxt={BUTTON_TEXT.SAVE_DRAFT}
          className="draft_btn d-none d-md-block"
          style={{
            background: getDisableData() ? "#d9d9d9" : "",
          }}
          isLoading={loadingState.isDraft}
          onClick={handleSaveAsDraft}
          disabled={getDisableData()}
        />
      </div>
    );
  }, [getDisableData, loadingState.isDraft, handleSaveAsDraft]);

  return draftButton;
};

export default StickyDraftButton;

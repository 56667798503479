/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import ApiLoader from "../../global/ApiLoader";
import Button from "../../global/Button";
import { CARD_TYPE } from "../../types";
import { showToast } from "../../utils";
import endpoints from "../../utils/endpoints";
import {
  makeDeleteCall,
  makeGetCall,
  makePatchCall,
} from "../../utils/Requests";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import DashboardWrapper from "./DashboardWrapper";
import { useNavigate } from "react-router-dom";
import AddCardPopupApple from "./Popups/AddCardPopupApple";

const PaymentMethodsApple = () => {
  const [allCards, setAllCards] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addCard, setAddCard] = useState<boolean>(false);
  const navigate = useNavigate();
  const getAllCards = () => {
    setIsLoading(true);
    makeGetCall({ url: endpoints.get_card_web })
      .then((res) => {
        if (res.status.code === 200) {
          setAllCards(res.data);
        } else {
          showToast(res.status.message, "error");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        showToast(err, "error");
        setIsLoading(false);
      });
  };
  const DeleteCard = (id: string) => {
    let apiPayload = { source_id: id };
    makeDeleteCall({ url: endpoints.payment_methods, apiPayload })
      .then((res) => {
        showToast(
          res.status.message,
          res.status.code === 200 ? "success" : "error"
        );
        getAllCards();
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };
  const handleCardDefault = (id: string) => {
    let apiPayload = { source_id: id, default: true };
    makePatchCall({ url: endpoints.payment_methods, apiPayload })
      .then((res) => {
        showToast(res.status.message, "success");
        getAllCards();
      })
      .catch((err) => showToast(err, "error"));
  };

  useEffect(() => {
    getAllCards();
    if (allCards.length > 0) {
      setAddCard(true);
    } else {
      setAddCard(false);
    }
  }, []);

  return (
    <DashboardWrapper showTab={true}>
      <div className="personal-details-wrapper">
        <div className="main-section-right profile-managment-right payment_wrapper left-sdie">
          <div className="profile_box">
            <div className="title">
              Apple pay test
              <img
                src="/static/backarrow.svg"
                alt=""
                className="back_arrow d-md-none"
                onClick={() => navigate(-1)}
              />
            </div>
            <div className="sub_title update-interests">
              <img
                src="/static/suitcase.svg"
                alt=""
                style={{ verticalAlign: "center" }}
              />{" "}
              Your card details are encrypted
            </div>
            <div className="paymet_card">
              {allCards.length > 0 && !addCard ? (
                <Button
                  btnTxt={"Add a new card"}
                  onClick={() => {
                    if (allCards.length < 8) {
                      setAddCard(true);
                    } else {
                      showToast(STRING_CONSTANTS.no_more_than_8_cards, "error");
                    }
                  }}
                />
              ) : (
                <Button
                  btnTxt="Add Your Card"
                  onClick={() => {
                    if (allCards.length < 8) {
                      setAddCard(true);
                    } else {
                      showToast(STRING_CONSTANTS.no_more_than_8_cards, "error");
                    }
                  }}
                />
              )}
              {addCard && (
                <AddCardPopupApple
                  show={addCard || !allCards.length}
                  onHide={() => setAddCard(false)}
                />
              )}
            </div>
          </div>
          {allCards ? (
            isLoading ? (
              <ApiLoader />
            ) : (
              allCards.map((card: any, index: number) => {
                return (
                  <div className="profile_box available_card" key={index}>
                    <div className="left_box">
                      {card.brand === CARD_TYPE.M ? (
                        <img src="/static/master.svg" alt="" />
                      ) : card.brand === CARD_TYPE.V ? (
                        <img src="/static/visa.svg" alt="" />
                      ) : null}
                      <div className="txtb">
                        <p>
                          {`${card.brand} (${card.last4}) ${card?.source ? `(${card.source})`: ''}`}
                        </p>
                        <p>
                          Exp: {card.expMonth}/{card.expYear.slice(-2)}
                        </p>
                        {card.default && (
                          <small>This is your default payment method</small>
                        )}
                      </div>
                    </div>
                    <div className="options">
                      <img src="/static/dots.svg" alt="" className="more" />
                      <div className="option_wrapper">
                        {!card.default && (
                          <div
                            className="delete"
                            onClick={() => {
                              handleCardDefault(card.id);
                            }}
                          >
                            <img src="/static/card.svg" alt="" />
                            Set card as default
                          </div>
                        )}
                        <div
                          className="delete"
                          onClick={() => {
                            DeleteCard(card.id);
                          }}
                        >
                          <img src="/static/greydelete.svg" alt="" />
                          Remove this card
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )
          ) : null}
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default PaymentMethodsApple;

import React, { useMemo } from "react";
import { Fragment } from "react";
import { HEADINGS } from "../../../utils/StringConstant";
import { apiConstants } from "../../../utils/Constants";
import { OutlineYellowButton } from "../../../global/OutlineButton";
import AddIconYellow from "../../../assets/img/icons/AddIconYellow";
import EventCardSection from "../EventsCardSection/EventCardSection";

const PackageListingsSidebar = React.memo(
  ({
    showEventListingsSidebar,
    setShowEventListingsSidebar,
    getValues,
    onDeleteEvent,
    handleEventEditClick,
    setShowAddEventSidebar,
    watch,
  }: {
    showEventListingsSidebar: boolean;
    setShowEventListingsSidebar: (value: boolean) => void;
    getValues: (field: string) => any;
    onDeleteEvent: (id: string) => void;
    handleEventEditClick: (id: string) => void;
    setShowAddEventSidebar: (value: boolean) => void;
    watch: any;
  }) => {
    const multipleEvents = useMemo(
      () => getValues(apiConstants.multipleEvents) || [],
      [getValues]
    );

    const renderEventsContent = useMemo(
      () =>
        multipleEvents.length > 0 && (
          <>
            <EventCardSection
              data={multipleEvents}
              onDeleteEvent={onDeleteEvent}
              handleEventEditClick={handleEventEditClick}
              setShowEventListingsSidebar={setShowEventListingsSidebar}
              viewAll={false}
              watch={watch}
            />
            <div>
              <OutlineYellowButton
                style={{
                  border: "1px solid #8f939a",
                }}
                text={HEADINGS.ADD_EVENT}
                icon={<AddIconYellow />}
                onClick={() => {
                  setShowEventListingsSidebar(false);
                  setShowAddEventSidebar(true);
                }}
              />
            </div>
          </>
        ),
      [
        multipleEvents,
        onDeleteEvent,
        handleEventEditClick,
        setShowEventListingsSidebar,
        setShowAddEventSidebar,
        watch,
      ]
    );

    if (!showEventListingsSidebar) return null;

    return (
      <div className="fixed-sidebar-left">
        <div className="sidebar-box-event">
          <div className="event-title mt-0">
            <img
              src="/static/cross-black.svg"
              onClick={() => setShowEventListingsSidebar(false)}
              className="close-btn"
              alt=""
            />
            <div className="filter-heading">Events</div>
          </div>
          <div className="inner-contet">
            <Fragment>{renderEventsContent}</Fragment>
          </div>
        </div>
      </div>
    );
  }
);

export default PackageListingsSidebar;

import React, { useMemo } from "react";
import { LISTING_TYPE } from "../../../types";
import { HEADINGS } from "../../../utils/StringConstant";
import { apiConstants } from "../../../utils/Constants";

const ListingHeading = ({
  watch,
  handleMainBack,
  handleSaveAsDraft,
}) => {
  const headingContent = useMemo(() => {
    return (
      <>
        <img
          src="/static/leftarrow.svg"
          alt=""
          className="back_arrow"
          onClick={handleMainBack}
        />
        <div className="d-flex justify-content-between d-md-none mb-1">
          <div
            className="mb-1 position-absolute pe-3"
            style={{ cursor: "pointer", right: "0px" }}
            onClick={handleSaveAsDraft}
          >
            <img src="/static/saveResDraft.png" alt="" />
          </div>
        </div>
        <img
          src="/static/leftarrow.svg"
          alt=""
          className="back_arrow d-none d-md-block"
          onClick={handleMainBack}
        />
        <div className="listing_heading_main">
          {watch(apiConstants.listingType) === LISTING_TYPE.M
            ? HEADINGS.PACKAGE
            : watch(apiConstants.listingType) === LISTING_TYPE.C
            ? HEADINGS.TEAM
            : HEADINGS.SESSION}
        </div>
      </>
    );
  }, [watch(apiConstants.listingType)]); // Dependencies for useMemo

  return headingContent;
};

export default ListingHeading;

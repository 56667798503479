import React from "react";

const BankTransferInfo = () => {
    return (
      <div className="bank_info">
        <img src="/static/info.svg" alt="" />
        <div>
          Payment will be transferred to your specified bank account within 7
          business days after the booking is completed.
        </div>
      </div>
    );
  };

export default React.memo(BankTransferInfo);
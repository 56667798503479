/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { LISTING_TYPE, USER_TYPE } from "../../types";
import ReactSimplyCarousel from "react-simply-carousel";
import {
  dateFormatter,
  downloadFile,
  formatAmount,
  formatNumber,
  getItemFromStore,
  scrollToTop,
  showToast,
} from "../../utils";
import {
  makeDeleteCall,
  makeGetCall,
  makeNextPageCall,
  makePatchCall,
  makePostCall,
} from "../../utils/Requests";
import GlobalHeader from "../Dashboard/GlobalHeader";
import endpoints from "../../utils/endpoints";
import ActiveListIcon from "../../assets/img/icons/ActiveListIcon";
import InActiveListIcon from "../../assets/img/icons/InActiveListIcon";
import IconPhysicalEvent from "../../assets/img/createListingIcon/physical-orange.svg";
import IconVirtualEvent from "../../assets/img/createListingIcon/virtual-blue.svg";
import IconAlert from "../../assets/img/createListingIcon/alert.svg";
import MoreIcon from "../../assets/img/icons/MoreIcon";
import DeleteIcon from "../../assets/img/icons/DeleteIcon";
import ActivateIcon from "../../assets/img/icons/ActivateIcon";
import EditIcon from "../../assets/img/icons/EditIcon";
import Button from "../../global/Button";
import MySchedulingCard from "./MySchedulingCard";
import ArchivedBookingCard from "./ArchivedBookingCard";
import GoogleMapContainer from "../../global/GoogleMapContainer";
import ApiLoader from "../../global/ApiLoader";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import {
  LeftArrowStickIcon,
  LocationGreyFillIcon,
  SpClockIcon,
} from "../../utils/Icons/svgs";
import TimeIcon from "../../assets/img/icons/TimeIcon";
import LocationIcon from "../../assets/img/icons/LocationIcon";
import { useOutsideClick } from "../../hooks";
import InfiniteScroll from "react-infinite-scroll-component";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";
import GlobalHeader2 from "../Dashboard/GlobalHeader2";
import GlobalFooter from "../Dashboard/GlobalFooter";
import Dropdown from "./Dropdown";
import CancelledBookingCard from "./CancelBookingCard";
import MediaCarousel from "./MediaCarousel";
import { gettingDistance } from "../../constants/helperConnectUser";
import ShareTag from "../ConnectUserProfile/EventListingCard/ShareTag";
import { Modal } from "react-bootstrap";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const currentYear = new Date().getFullYear();

const ViewListing = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listing, setListing] = useState<any>([]);
  const [, setNextUrl] = useState<any>(null);
  const [moreNextUrl, setMoreNextUrl] = useState<any>(null);
  const [openIndex, setOpenIndex] = useState<null | number>(null);
  const [scheduleData, setScheduleData] = useState<any>();
  const [sideList, setSideList] = useState<Boolean>(false);
  const [mapCenter, setMapCenter] = useState<any>();
  const [schedulLoading, setScheduleLoading] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<String>(null);
  const [earningMonthData, setearningMonthData] = useState<any>([]);
  const [selectedYear, setSelectedYear] = useState<any>(currentYear);
  const [showCsvPopup, setShowCsvPopup] = useState<boolean>(false);
  const [userCurrentLocation, setUserCurrentLocation] = useState({
    lat: 0,
    long: 0,
  });
  const [showEarningCsvPopup, setShowEarningCsvPopup] =
    useState<boolean>(false);
  const [totalEarning, setTotalEarning] = useState<any>(0);
  const [selectedBookingtype, setSelectedBookingType] = useState("upcoming");
  const [showInactivePopup, setShowInactivePopup] = useState(false);
  const ref = useRef(null);
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { isMobile } = useCheckDeviceScreen();
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [active, setActive] = useState<any>(
    location?.state?.activeType === "I" ? "inactive" : "active"
  );
  const [showShare, setShowShare] = useState(false);
  const [detailsData, setDetailsData] = useState(null);
  const scrollContainerRef = useRef(null);
  const [isAtLeftEdge, setIsAtLeftEdge] = useState(true);
  const [isAtRightEdge, setIsAtRightEdge] = useState(true);
  const scrollByAmount = (amount: any) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current?.scrollBy({
        left: amount,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    // Assuming `listing` is the array used to render slides
    const totalSlides = listing.length;
    const slidesToShow = isMobile ? 1.3 : 3.2; // or use Math.ceil for whole numbers

    // Check if on the last slide whenever currentSlide changes
    setIsLastSlide(currentSlide >= totalSlides - Math.ceil(slidesToShow));
  }, [currentSlide, listing.length, isMobile]);

  useEffect(() => {
    sliderRef?.current?.slickGoTo(currentSlide);
  }, [currentSlide]);

  const redirectionRef = useRef(null);

  useEffect(() => {
    if (location?.state?.activeType === "I" && redirectionRef.current) {
      redirectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile ? 1.3 : 3.2,
    slidesToScroll: isMobile ? 1 : 3,
    autoplay: false,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
    afterChange: (current) => {
      // Assuming `listing` is the array used to render slides
      const totalSlides = listing.length;
      const slidesToShow = isMobile ? 1.3 : 3.2; // or use Math.ceil for whole numbers

      // Set isLastSlide state based on the current slide
      setIsLastSlide(current >= totalSlides - Math.ceil(slidesToShow));
    },
  };

  const options = [];
  for (let year = 2023; year <= currentYear + 1; year++) {
    options.push({ label: year, id: year });
  }
  function truncateString(str = "", length) {
    if (str?.length > length) {
      return str.substring(0, length) + "...";
    } else {
      return str;
    }
  }
  const earningData = {
    labels,
    datasets: [
      {
        data: earningMonthData,
        label: "Earnings",
        backgroundColor: "#EE762F",
      },
    ],
  };

  let monthDateArr = [];
  const navigate = useNavigate();

  if (scheduleData) {
    if (scheduleData !== undefined && scheduleData.length > 0) {
      monthDateArr.push({
        month: moment(scheduleData?.[0]?.date).format("MMMM"),
        data: [scheduleData?.[0]],
      });
      let mocount = 0;
      for (let m = 1; m < scheduleData.length; m++) {
        if (
          monthDateArr?.[mocount].month ===
          moment(scheduleData?.[m]?.date).format("MMMM")
        ) {
          monthDateArr[mocount].data.push(scheduleData?.[m]);
        } else {
          mocount++;
          monthDateArr.push({
            month: moment(scheduleData?.[m].date).format("MMMM"),
            data: [scheduleData?.[m]],
          });
        }
      }
    }
  }
  // const EventImageCarousel = (data: any) => {
  //   const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  //   return (
  //     <div className="carousal-item-custom">
  //       {data.item.documents?.length > 1 ? (
  //         <ReactSimplyCarousel
  //           activeSlideIndex={activeSlideIndex}
  //           onRequestChange={setActiveSlideIndex}
  //           itemsToShow={1}
  //           itemsToScroll={1}
  //           dotsNav={{
  //             show: true,
  //             containerProps: {
  //               className: "dots-nav-container",
  //               style: {
  //                 position: "absolute",
  //                 bottom: "10%",
  //                 gap: "6px",
  //                 width: "100%",
  //                 display: "flex",
  //                 justifyContent: "center",
  //               },
  //             },
  //             itemBtnProps: {
  //               style: {
  //                 height: 6,
  //                 width: 6,
  //                 borderRadius: "50%",
  //                 border: 0,
  //                 background: "rgba(255, 255, 255, 0.4)",
  //               },
  //             },
  //             activeItemBtnProps: {
  //               style: {
  //                 height: 6,
  //                 width: 6,
  //                 borderRadius: "50%",
  //                 border: 0,
  //                 background: "white",
  //               },
  //             },
  //           }}
  //           forwardBtnProps={{
  //             //here you can also pass className, or any other button element attributes
  //             className: "image-carousel-button",

  //             style: {
  //               background: "rgba(255, 255, 255, 0.4)",
  //               borderRadius: "50%",
  //               border: "none",
  //               cursor: "pointer",
  //               height: 24,
  //               width: 24,
  //               position: "absolute",
  //               zIndex: "0",
  //               top: "50%",
  //               right: "16px",
  //               translate: "0 -50%",
  //               display: "none",
  //               justifyContent: "center",
  //               alignItems: "center",
  //             },
  //             children: (
  //               <img
  //                 style={{ width: "16px", height: "16px" }}
  //                 alt=""
  //                 src="/static/chevron-black-right.svg"
  //               />
  //             ),
  //           }}
  //           backwardBtnProps={{
  //             //here you can also pass className, or any other button element attributes
  //             className: "image-carousel-button",
  //             style: {
  //               background: "rgba(255, 255, 255, 0.4)",
  //               borderRadius: "50%",
  //               border: "none",
  //               cursor: "pointer",
  //               height: 24,
  //               width: 24,
  //               position: "absolute",
  //               zIndex: "99",
  //               top: "50%",
  //               left: "16px",
  //               translate: "0 -50%",
  //               display: "none",
  //               justifyContent: "center",
  //               alignItems: "center",
  //             },
  //             children: (
  //               <img
  //                 style={{ width: "16px", height: "16px" }}
  //                 alt=""
  //                 src="/static/chevron-black-left.svg"
  //               />
  //             ),
  //           }}
  //           speed={400}
  //           easing="linear"
  //         >
  //           {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}

  //           {data.item?.documents.map((media: any) =>
  //             /\.(mp4|mov|avi)$/.test(media?.documentName?.toLowerCase()) ? (
  //               <>
  //                 <video
  //                   autoPlay
  //                   playsInline
  //                   loop
  //                   muted
  //                   src={data.item?.documents[0]?.uploadDocumentUrl}
  //                   style={{
  //                     borderRadius: "12px 12px 0 0",
  //                     objectFit: "cover",
  //                     width: "100%",
  //                     height: "230px",
  //                   }}
  //                   onClick={(e) => {
  //                     e.preventDefault();
  //                     scrollToTop();
  //                     window.open("/listing-two/" + data.item.id);
  //                   }}
  //                 >
  //                   <source src={"" || ""} type="video/mp4" />
  //                 </video>
  //               </>
  //             ) : (
  //               <img
  //                 src={media?.uploadDocumentUrl || "/static/expertise2.png"}
  //                 className="event_img"
  //                 //preload="none"
  //                 // autoPlay
  //                 controls
  //                 controlsList="nodownload"
  //                 playsInline
  //                 loop
  //                 muted
  //                 style={{
  //                   cursor: "pointer",
  //                   borderRadius: "12px 12px 0 0",
  //                   objectFit: "cover",
  //                   width: "100%",
  //                   height: "230px",
  //                 }}
  //                 onClick={(e) => {
  //                   e.preventDefault();
  //                   scrollToTop();
  //                   window.open("/listing-two/" + data.item.id);
  //                 }}
  //               />
  //             )
  //           )}
  //         </ReactSimplyCarousel>
  //       ) : data.item?.documents?.length == 1 ? (
  //         /\.(mp4|mov|avi)$/.test(
  //           data.item?.documents[0].documentName?.toLowerCase()
  //         ) ? (
  //           <video
  //             autoPlay
  //             playsInline
  //             loop
  //             muted
  //             src={data.item?.documents[0]?.uploadDocumentUrl}
  //             style={{
  //               borderRadius: "12px 12px 0 0",
  //               objectFit: "cover",
  //               width: "100%",
  //               height: "230px",
  //             }}
  //             onClick={(e) => {
  //               e.preventDefault();
  //               scrollToTop();
  //               window.open("/listing-two/" + data.item.id);
  //             }}
  //           >
  //             <source src={"" || ""} type="video/mp4" />
  //           </video>
  //         ) : (
  //           <div className="individual-image-video-block">
  //             <div className="individual-image-video-block-2">
  //               <img
  //                 src={
  //                   data.item?.documents[0].uploadDocumentUrl ||
  //                   "/static/expertise2.png"
  //                 }
  //                 // className="event_img"
  //                 style={{
  //                   borderRadius: "12px 12px 0 0",
  //                   objectFit: "cover",
  //                   width: "100%",
  //                   height: "230px",
  //                   cursor: "pointer",
  //                 }}
  //                 alt="event-img"
  //                 loading="lazy"
  //                 onClick={(e) => {
  //                   e.preventDefault();
  //                   scrollToTop();
  //                   window.open("/listing-two/" + data.item.id);
  //                 }}
  //               />
  //             </div>
  //           </div>
  //         )
  //       ) : /\.(mp4|mov|avi)$/.test(data.item?.media?.toLowerCase()) ? (
  //         <>
  //           <div className="individual-image-video-block">
  //             <div className="individual-image-video-block-2">
  //               <video
  //                 disablePictureInPicture
  //                 src={data.item?.media}
  //                 className="event_img"
  //                 //preload="none"
  //                 // autoPlay
  //                 controls
  //                 controlsList="nodownload noplaybackrate nofullscreen"
  //                 // playsInline
  //                 // loop
  //                 muted
  //                 style={{
  //                   cursor: "pointer",
  //                   borderRadius: "12px 12px 0 0",
  //                   objectFit: "cover",
  //                   width: "100%",
  //                   height: "230px",
  //                 }}
  //                 onClick={(e) => {
  //                   e.preventDefault();
  //                   scrollToTop();
  //                   window.open("/listing-two/" + data.item.id);
  //                 }}
  //               >
  //                 <source src={data.item?.media} />
  //               </video>
  //             </div>
  //           </div>
  //         </>
  //       ) : (
  //         <div className="individual-image-video-block">
  //           <div className="individual-image-video-block-2">
  //             <img
  //               src={data.item?.media || "/static/expertise2.png"}
  //               // className="event_img"
  //               style={{
  //                 borderRadius: "12px 12px 0 0",
  //                 objectFit: "cover",
  //                 width: "100%",
  //                 height: "230px",
  //                 cursor: "pointer",
  //               }}
  //               alt=""
  //               loading="lazy"
  //               onClick={(e) => {
  //                 e.preventDefault();
  //                 scrollToTop();
  //                 window.open("/listing-two/" + data.item.id);
  //               }}
  //             />
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   );
  // };

  const getListingData = () => {
    // setListing([]);
    setIsLoading(true);
    makeGetCall({
      url:
        endpoints.mylisting_new +
        `?is_active=${active === "active" ? true : false}`,
      params:
        userCurrentLocation.long !== 0 && userCurrentLocation.lat !== 0
          ? { point: `${userCurrentLocation.long},${userCurrentLocation.lat}` }
          : {},
    })
      .then((res) => {
        if (res?.results) {
          setListing(res?.results);
          setNextUrl(res?.next);
        } /*  else {
          showToast(res.status.message, "error");
        } */
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };

  useEffect(() => {
    getListingData();
  }, [active, userCurrentLocation]);

  useEffect(() => {
    setIsLoading(true);
    // makeGetCall({ url: endpoints.listings_earning })
    //   .then((res) => {
    //     if (res.status.code === 200) {
    //       setTotalEarning(res.data);
    //       setIsLoading(false);
    //     } else {
    //       showToast(res?.status?.message, "error");
    //     }
    //   })
    //   .catch((err) => showToast(err, "error"));
  }, []);

  useEffect(() => {
    //get earning graph data
    setIsLoading(true);
    makeGetCall({
      url: `${endpoints.listing_monthly_earning}?year=${selectedYear}`,
    })
      .then((res) => {
        if (res.data) {
          const earningList = res.data?.monthlyEarning;
          const data = earningList?.map(
            (monthData: any) => monthData?.totalNetAmount
          );
          setTotalEarning(res.data?.totalEarning);
          setearningMonthData(data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  }, [selectedYear]);

  const handleBookingType = () => {
    setScheduleLoading(true);
    makeGetCall({
      url:
        endpoints.listingSchedule +
        `${
          selectedBookingtype !== "upcoming"
            ? `?type=${selectedBookingtype}`
            : ""
        }`,
    })
      .then((res) => {
        setScheduleData(res.results);
        setMoreNextUrl(res?.next);
        setScheduleLoading(false);
      })
      .catch((err) => {
        setScheduleLoading(false);
        showToast(err, "error");
      });
  };

  useEffect(() => {
    handleBookingType();
  }, [selectedBookingtype]);

  const handleMapCenter = (centerData) => {
    setMapCenter(centerData);
  };

  const fetchMoreData = () => {
    if (!moreNextUrl) return;
    setIsLoading(true);
    makeNextPageCall({ url: moreNextUrl }).then((res) => {
      const tempData = [...scheduleData, ...res.results];
      setIsLoading(false);
      setMoreNextUrl(res?.next);
      setScheduleData(tempData as never);
    });
  };

  const deleteDraftItem = () => {
    setShowDeletePopup(false);
    if (deleteId) {
      makeDeleteCall({
        url: endpoints.listing + `${deleteId}`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setDeleteId(null);
            showToast(res.status?.message, "success");
            setOpenIndex(null);
            getListingData();
          } else {
            showToast(res.status?.message, "error");
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };

  const downloadCsv = () => {
    setIsLoading(true);
    makeGetCall({ url: endpoints.listings_booking_csv })
      .then((res) => {
        //not getting any status code yet
        downloadFile(res);
        setShowCsvPopup(false);
        setIsLoading(false);
        if (res.status.code === 200) {
          setShowCsvPopup(false);
        } else {
          showToast(res?.status?.message, "error");
        }
      })
      .catch((err) => showToast(err, "error"));
  };
  const downloadEarningCsv = () => {
    setIsLoading(true);
    makeGetCall({ url: endpoints.listings_earning_csv })
      .then((res) => {
        //not getting any status code yet
        downloadFile(res);
        setShowEarningCsvPopup(false);
        setIsLoading(false);
        if (res.status.code === 200) {
          setShowEarningCsvPopup(false);
        } else {
          showToast(res?.status?.message, "error");
        }
      })
      .catch((err) => showToast(err, "error"));
  };

  const makeInactiveList = () => {
    if (deleteId) {
      makePatchCall({
        url: endpoints.listing + `${deleteId}/mark-inactive/`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setDeleteId(null);
            showToast(res.status?.message, "success");
            setOpenIndex(null);

            getListingData();
            setShowInactivePopup(false);
          } else {
            showToast(res.status?.message, "error");
            setShowInactivePopup(false);
          }
        })
        .catch((err) => {
          showToast(err, "error");
          setShowInactivePopup(false);
        });
    }
  };

  useOutsideClick(ref, () => {
    setOpenIndex(null);
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = scrollContainerRef.current;

      if (!scrollContainer) return;

      const scrollLeft = scrollContainer.scrollLeft;
      const scrollWidth = scrollContainer.scrollWidth;
      const clientWidth = scrollContainer.clientWidth;

      setIsAtLeftEdge(scrollLeft === 0);
      setIsAtRightEdge(scrollLeft + clientWidth + 50 >= scrollWidth);
    };

    const scrollContainer = scrollContainerRef.current;
    scrollContainer?.addEventListener("scroll", handleScroll);

    // Initial check
    handleScroll();

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    // Check if Geolocation API is available in the browser

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserCurrentLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        },
        (err) => {
          showToast(
            "We kindly ask for your location to enhance your app experience; however, we regret to inform you that without this information, we are unable to proceed further.",
            "error"
          );
        }
      );
    } else {
      showToast(
        "It looks like geolocation is not available in your browser.",
        "error"
      );
    }
  }, []);

  const handleNotShowShare = () => {
    setShowShare(false);
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(detailsData?.dynamicLink);

      showToast("Text copied!", "success");
    } catch (err) {
      showToast("Failed to copy the text. Please try again.", "error");
    }
  };
  const encodedMessage = encodeURIComponent("Check out this link!");
  const encodedLink = encodeURIComponent(detailsData?.dynamicLink);

  const handleWhatsAppShare = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedMessage}%20${encodedLink}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleSmsShare = () => {
    const smsUrl = `sms:?body=${encodedMessage}%20${encodedLink}`;
    window.open(smsUrl, "_blank");
  };

  const handleMessengerShare = () => {
    const messengerUrl = `fb-messenger://share?link=${encodedLink}`;
    window.open(messengerUrl, "_blank");
  };

  const handleTwitterShare = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedMessage}%20${encodedLink}`;
    window.open(twitterUrl, "_blank");
  };

  const handleFacebookShare = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
    window.open(facebookUrl, "_blank");
  };

  const handleEmailShare = () => {
    const emailUrl = `mailto:?subject=${encodedMessage}&body=${encodedMessage}%20${encodedLink}`;
    window.open(emailUrl, "_blank");
  };

  return (
    <>
      <GlobalHeader2 />
      <div className="view-listing-page-wrap" style={{ marginTop: "0px" }}>
        {/* <div className="upper-title d-flex d-md-none justify-content-between bg-white p-3"></div> */}
        {getItemFromStore("userProfile")?.userType !== "Guest" ? (
          <div className="view-linsting-white">
            <div className="upper-title">
              <span className="listing-title d-md-none ">My Listings</span>
              <div className="d-md-none" onClick={() => setShowCsvPopup(true)}>
                <img src="/static/doc-arrow-down.png" alt="" />
              </div>
            </div>
            <div className="graph-section-top">
              <div className="left">
                <div className="section-title">{`$${totalEarning} YTD`}</div>
                <div className="section-sub-title">View earnings by month</div>
              </div>
              <div
                className="right d-block d-md-none"
                style={{ marginTop: "24px" }}
              >
                <Dropdown
                  title={String(currentYear)}
                  options={options}
                  selectedDropdownValue={selectedYear}
                  setSelectedDropdownValue={setSelectedYear}
                />
              </div>
              <div className="right d-none d-md-flex">
                <Dropdown
                  title={String(currentYear)}
                  options={options}
                  selectedDropdownValue={selectedYear}
                  setSelectedDropdownValue={setSelectedYear}
                />
                {/* <select
                  className="year-select-box"
                  value={selectedYear}
                  onChange={(e) => {
                    setSelectedYear(e.target.value);
                  }}
                >
                  {options}
                </select> */}
                <Button
                  btnTxt="Export"
                  className="export-btn"
                  btnImg="/static/white-export.svg"
                  onClick={() => setShowEarningCsvPopup(true)}
                />
              </div>
            </div>

            <Bar options={options} data={earningData} />
          </div>
        ) : null}
        {getItemFromStore("userProfile")?.userType !== "Guest" && (
          <div className="alert-text-wrapper">
            {" "}
            <img src={IconAlert} />{" "}
            <div>
              Your earnings shown here are before the deduction of booking fee.
              To learn more, please check the{" "}
              <span
                style={{ fontWeight: "600" }}
                className="cursor-pointer"
                onClick={() => navigate("/policies")}
              >
                Terms & Conditions.
              </span>
            </div>{" "}
          </div>
        )}
        <div
          className="view-linsting-white mt-28"
          style={{ paddingRight: "0px", overflowX: "hidden" }}
        >
          <div
            style={{ position: "relative", marginBottom: "16px" }}
            ref={redirectionRef}
          >
            <div className="view-title-box-wrap">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: "24px",
                }}
              >
                <div style={{ width: "100%" }}>
                  <div
                    className="section-title"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    My Created Listings{" "}
                    <div
                      className=" d-flex d-md-none  plus-btn "
                      onClick={() => {
                        // if (
                        //   getItemFromStore("userProfile")?.userType ===
                        //   USER_TYPE.GUEST
                        // ) {
                        //   navigate(ROUTE_CONSTANTS.create_listing);
                        // } else if (
                        //   getItemFromStore("userProfile")?.userType !==
                        //     USER_TYPE.GUEST &&
                        //   getItemFromStore("userProfile")?.listingBalance > 0
                        // ) {
                        navigate(ROUTE_CONSTANTS.select_listing);
                        // } else {
                        //   navigate(ROUTE_CONSTANTS.listing_block);
                        // }
                      }}
                    >
                      <img src="/static/white-add.png" alt="" />
                    </div>{" "}
                  </div>
                  <div className="section-sub-title mt-1">
                    Track your classes with Active Listings - all in one place.
                  </div>
                </div>
                <div className="see-all-listing-button-new d-none d-md-block">
                  <Link to="/my-listings">
                    <img alt="" src="/static/see-all-button.svg" />
                  </Link>
                </div>

                <div className="d-none d-md-block">
                  <Button
                    btnImg="/static/plusadd.svg"
                    btnTxt="Create Listing"
                    className="add_listing_btn align-items-center"
                    onClick={() => {
                      // if (
                      //   getItemFromStore("userProfile")?.userType ===
                      //   USER_TYPE.GUEST
                      // ) {
                      //   navigate(ROUTE_CONSTANTS.create_listing);
                      // } else if (
                      //   getItemFromStore("userProfile")?.userType !==
                      //     USER_TYPE.GUEST &&
                      //   getItemFromStore("userProfile")?.listingBalance > 0
                      // ) {
                      navigate(ROUTE_CONSTANTS.select_listing);
                      // } else {
                      //   navigate(ROUTE_CONSTANTS.listing_block);
                      // }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="active-inactive-listing-box">
            <div
              className="item cursor-pointer"
              onClick={() => setActive("active")}
            >
              <div className={active === "active" ? "active" : ""}>Active</div>
            </div>
            <div
              className="item cursor-pointer"
              onClick={() => setActive("inactive")}
            >
              <div className={active === "inactive" ? "active" : ""}>
                Inactive
              </div>
            </div>
          </div>
          {isLoading ? (
            <ApiLoader />
          ) : listing.length > 0 ? (
            <>
              {/* <div className="slider-container"> */}
              {/* <Slider {...settings} ref={sliderRef} className="custom-slick-slider"  > */}
              {
                <div
                  className="created-listing-wrapper"
                  ref={scrollContainerRef}
                >
                  {listing?.map((item: any, index: any) => {
                    return (
                      <div
                        className="carousel-card"
                        style={{
                          cursor: "pointer",
                        }}
                        key={item.title}
                        onClick={() => window.open("/listing-two/" + item.id)}
                      >
                        <div style={{ height: "230px", width: "295px" }}>
                          <MediaCarousel media={item?.documents} />
                        </div>
                        {/* <div className="list-type-wrapper">
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {item?.listingType === "S" ||
                            item?.listingType === "SPECIAL" ? (
                              <img
                                src="/static/Sport-white.svg"
                                alt="Sport Balls"
                              />
                            ) : item?.listingType === "P" ? (
                              <img
                                src="/static/box-white.svg"
                                alt="Sport Balls"
                              />
                            ) : (
                              <img
                                src="/static/people-white.svg"
                                alt="Sport Balls"
                              />
                            )}
                          </span>
                          <span>
                            {item?.listingType === "S" ||
                            item?.listingType === "SPECIAL"
                              ? "Session"
                              : item?.listingType === "P"
                              ? "Package"
                              : "Team"}
                          </span>
                        </div> */}
                        {item.listingEventType !== "V" ? (
                          <>
                            <img
                              className="event_type_mylisting"
                              src="/static/event-type2.svg"
                              alt=""
                            />
                          </>
                        ) : (
                          <>
                            <span className="event_type_mylisting">
                              <img src="/static/event-type.svg" alt="" />
                            </span>
                          </>
                        )}
                        {item?.distance !== null && (
                          <div className="distance-wrapper">
                            {gettingDistance(item?.distance || 0)}
                          </div>
                        )}
                        {item?.listingType !== LISTING_TYPE.C && (
                          <div className="price-wrapper">
                            {item?.listingType === LISTING_TYPE.C
                              ? "Join"
                              : item?.price || item?.monthlyCost
                              ? Number(item?.price) < 1
                                ? "Free"
                                : `AUD ${item?.price || item?.monthlyCost}`
                              : "Free"}
                          </div>
                        )}

                        {active === "active" && (
                          <div className="card-right-buttons-wrap">
                            <div className="share_layer">
                              <ShareTag
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowShare(true);
                                  setDetailsData(item);
                                }}
                              />
                            </div>
                            <div
                              className="more-popup-listing"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (openIndex === index) {
                                  setOpenIndex(null);
                                } else {
                                  setOpenIndex(index);
                                }
                              }}
                            >
                              <MoreIcon />
                            </div>
                          </div>
                        )}
                        {openIndex === index ? (
                          <div
                            className="more-popup-item-wrap "
                            style={{ zIndex: "999999" }}
                            ref={ref}
                          >
                            {item.isListingEditable ? (
                              <Link
                                className="event-link-title"
                                to={`/edit-listing/${item.id}`}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div className="more-icon-item">
                                  <span>
                                    <EditIcon />
                                  </span>
                                  Edit Listing
                                </div>
                              </Link>
                            ) : null}
                            {item.isActive ? (
                              <div
                                className="more-icon-item mb-0"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDeleteId(item.id);
                                  setShowInactivePopup(true);
                                  setOpenIndex(null);
                                }}
                              >
                                <span>
                                  <ActivateIcon />
                                </span>
                                {item.isActive ? "Inactive" : "Activate"}
                              </div>
                            ) : null}
                            {item.isActive ? null : (
                              <div
                                style={{ marginBottom: "0px" }}
                                className="more-icon-item"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDeleteId(item.id);
                                  setShowDeletePopup(true);
                                  setOpenIndex(null);
                                }}
                              >
                                <span>
                                  <DeleteIcon />
                                </span>
                                Delete Listing
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        <div style={{ padding: "16px 12px 16px 12px" }}>
                          <div
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontWeight: "600",
                              lineHeight: "20px",
                              color: "#25272D",
                            }}
                          >
                            {truncateString(item.title, 27)}
                          </div>
                          {item.listingEventType !== "V" && (
                            <div
                              style={{
                                marginTop: "10px",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontWeight: "400",
                                lineHeight: "18px",
                                color: "#25272D",
                                display: "flex",
                              }}
                            >
                              <img
                                src="/static/ion_location-outline.svg"
                                alt=""
                              />
                              <span style={{ paddingLeft: "4px" }}>
                                {item?.listingType === "S" ||
                                item?.listingType === "SPECIAL" ||
                                item?.listingType === "P"
                                  ? truncateString(
                                      item?.events?.[0]?.address?.address,
                                      38
                                    )
                                  : truncateString(
                                      item?.scheduleDetails.address?.address,
                                      38
                                    )}
                              </span>
                            </div>
                          )}
                          {(item?.listingType === "S" ||
                            item?.listingType === "SPECIAL" ||
                            item?.listingType === "P") && (
                            <div
                              style={{
                                marginTop: "6px",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontWeight: "400",
                                lineHeight: "18px",
                                color: "#25272D",
                                display: "flex",
                              }}
                            >
                              <img src="/static/calendar-2.svg" alt="" />
                              <span style={{ paddingLeft: "4px" }}>
                                {moment(item?.events?.[0]?.date).format(
                                  "DD MMM, YYYY"
                                ) + " "}
                                <img
                                  src="/static/EmptyDot.svg"
                                  style={{
                                    marginBottom: "3px",
                                    marginRight: "4px",
                                  }}
                                />
                                {moment(
                                  item?.events?.[0]?.startTime?.substring(0, 5),
                                  ["HH:mm"]
                                ).format("hh:mmA")}{" "}
                                -{" "}
                                {moment(
                                  item?.events?.[0]?.endTime?.substring(0, 5),
                                  ["HH:mm"]
                                ).format("hh:mmA")}
                              </span>
                            </div>
                          )}
                          {/* <div
                            style={{
                              display: "flex",
                              gap: "4px",
                              marginTop: "10px",
                              fontFamily: "Poppins",
                              fontSize: "12px",
                              fontWeight: "500",
                              lineHeight: "16px",
                            }}
                          >
                            {" "}
                            <span style={{ color: "#6C6D6F" }}>
                              Event Type:
                            </span>
                            <span
                              style={{
                                color:
                                  item.listingEventType === "V"
                                    ? "#0099FF"
                                    : "#EE7830",
                              }}
                            >
                              {item.listingEventType === "V"
                                ? "Virtual"
                                : "Physical"}
                            </span>
                            <img
                              src={
                                item.listingEventType === "V"
                                  ? IconVirtualEvent
                                  : IconPhysicalEvent
                              }
                            />
                          </div> */}
                        </div>
                        {item.isActive ? (
                          <div className="activeList">
                            <span>
                              <ActiveListIcon />
                            </span>
                            Active
                          </div>
                        ) : (
                          <div className="inactiveList">
                            <span>
                              <InActiveListIcon />
                            </span>
                            Inactive
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              }
              {/* </Slider> */}
              {/* </div> */}
              <div
                className="see-all-button-mobile"
                style={{ paddingTop: "16px", textAlign: "center" }}
              >
                <Link to="/my-listings">
                  <img alt="" src="/static/see-all-button.svg" />
                </Link>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  onClick={() => scrollByAmount(-400)}
                  className="carousel-button created-listing-btn "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M10.2955 19.7159C10.6889 20.1036 11.322 20.0989 11.7097 19.7055C12.0973 19.3121 12.0926 18.6789 11.6992 18.2913L6.3289 13L20 13C20.5523 13 21 12.5522 21 12C21 11.4477 20.5523 11 20 11L6.33539 11L11.6992 5.71501C12.0926 5.32739 12.0973 4.69424 11.7097 4.30083C11.322 3.90743 10.6889 3.90274 10.2955 4.29036L3.3713 11.1127C2.87439 11.6024 2.87439 12.4039 3.3713 12.8936L10.2955 19.7159Z"
                      fill={currentSlide < 1 ? "#BDBDBD" : "#25272D"}
                    />
                  </svg>
                </div>
                <div
                  onClick={() => scrollByAmount(400)}
                  className="carousel-button created-listing-btn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    fill="none"
                  >
                    <path
                      d="M10.7045 15.7159C10.3111 16.1036 9.67795 16.0989 9.29033 15.7055C8.90271 15.3121 8.9074 14.6789 9.3008 14.2913L14.6711 8.99996L1 8.99996C0.447715 8.99996 3.73713e-07 8.55224 3.49572e-07 7.99996C3.25431e-07 7.44767 0.447716 6.99996 1 6.99996L14.6646 6.99996L9.3008 1.71501C8.9074 1.32739 8.90271 0.69424 9.29033 0.300834C9.67795 -0.0925715 10.3111 -0.0972598 10.7045 0.290361L17.6287 7.11275C18.1256 7.60236 18.1256 8.40395 17.6287 8.89356L10.7045 15.7159Z"
                      fill={isLastSlide ? "#BDBDBD" : "#25272D"}
                    />
                  </svg>
                </div>
              </div>
            </>
          ) : (
            <div className="empty-data mx-auto d-flex justify-content-center">
              <div className="d-flex flex-column align-items-center">
                <img src="/static/no-lisitng.svg" alt="" />
                <p>No listings available</p>
                <p style={{ fontSize: 12 }}>
                  Your listings are all clear today! Check back here later, or
                  explore new listings.
                </p>
              </div>
            </div>
          )}
        </div>

      </div>
      <GlobalFooter />
      {sideList ? (
        <div className="map-fixed-sidebar">
          <div className="map-sidebar-box">
            <div className="map-heading d-none d-md-flex">
              <img
                src="/static/cross-black.svg"
                onClick={() => setSideList(false)}
                className="close-btn"
                alt=""
              />
              <p>Maps</p>
            </div>
            <div className="sb-map-container">
              <div className="d-md-none">
                <GlobalHeader />
              </div>
              <div
                className="map-back-icon d-md-none"
                onClick={() => setSideList(false)}
              >
                <LeftArrowStickIcon />
              </div>
              <GoogleMapContainer height="100%" center={mapCenter} />
            </div>
          </div>
        </div>
      ) : null}

      {showDeletePopup ? (
        <div className={"modal show"}>
          <div className="modal-section log_out_modal">
            <div className="modal-top-part">
              <h5>Are you sure?</h5>
              {/* <p>{STRING_CONSTANTS.logout_desc}</p> */}
            </div>
            <div className="button_option">
              <Button
                btnTxt="Delete"
                className={"backtologin"}
                onClick={deleteDraftItem}
              />
              <Button
                btnTxt="Cancel"
                className={"backtologin cancel"}
                onClick={() => setShowDeletePopup(false)}
              />
            </div>
          </div>
        </div>
      ) : null}

      {showCsvPopup ? (
        <div className={"modal show"}>
          <div className="modal-section log_out_modal csv-modal-wrap">
            <div className="close-btn-csv">
              <img
                src="/static/cross-black.svg"
                onClick={() => {
                  setShowCsvPopup(false);
                }}
                className="close-btn"
                alt=""
              />
            </div>
            <div className="modal-top-part">
              <div>
                <img alt="" src="/static/file-type-excel.svg" />
              </div>

              <h5>Export Your Bookings</h5>
              <p>
                Effortlessly transfer your listings to an Excel spreadsheet for
                easy organization and analysis.
              </p>
            </div>
            <div className="button_option">
              <Button
                btnTxt="Download as Excel(.csv)"
                className={"backtologin"}
                onClick={downloadCsv}
              />
              <Button
                btnTxt="Not now"
                className={"backtologin notnow"}
                onClick={() => setShowCsvPopup(false)}
              />
            </div>
          </div>
        </div>
      ) : null}

      {showEarningCsvPopup ? (
        <div className={"modal show"}>
          <div className="modal-section log_out_modal csv-modal-wrap">
            <div className="close-btn-csv">
              <img
                src="/static/cross-black.svg"
                onClick={() => {
                  setShowEarningCsvPopup(false);
                }}
                className="close-btn"
                alt=""
              />
            </div>
            <div className="modal-top-part">
              <div>
                <img alt="" src="/static/file-type-excel.svg" />
              </div>

              <h5>Export Your Listings</h5>
              <p>
                Effortlessly transfer your listings to an Excel spreadsheet for
                easy organization and analysis.
              </p>
            </div>
            <div className="button_option">
              <Button
                btnTxt="Download as Excel(.csv)"
                className={"backtologin"}
                onClick={downloadEarningCsv}
              />
              <Button
                btnTxt="Not now"
                className={"backtologin notnow"}
                onClick={() => setShowEarningCsvPopup(false)}
              />
            </div>
          </div>
        </div>
      ) : null}

      {showInactivePopup ? (
        <div className="modal-cancel-event show">
          <div className="modal-section" style={{ gap: "20px" }}>
            <div className="modal-top-part">
              <button className="close">
                <img
                  src="/static/dismiss-cross-round-black.svg"
                  alt=""
                  onClick={() => setShowInactivePopup(false)}
                />
              </button>

              <h5>Are You Sure</h5>
              <p>
                Once the listing is inactivated we won’t be able to recover the
                information.
              </p>
            </div>

            <Button
              btnTxt="Yes, Inactive"
              className={"backtologin"}
              onClick={() => {
                makeInactiveList();
              }}
            />
            <Button
              btnTxt="Go back"
              className={"goback backtologin"}
              onClick={() => setShowInactivePopup(false)}
            />
          </div>
        </div>
      ) : null}

      <Modal
        show={showShare}
        onHide={handleNotShowShare}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="share_modal_layout">
            <img
              src="/static/close.svg"
              alt="Close"
              style={{ cursor: "pointer" }}
              onClick={handleNotShowShare}
              className="share_modal_close_btn"
            />
            <h4>Share Listing</h4>
            <div className="share_modal_details">
              {detailsData?.thumbnail ? (
                <img
                  src={detailsData?.thumbnail || "/static/expertise2.png"}
                  className="event_img"
                  alt=""
                  style={{
                    width: "110px",
                    height: "86px",
                    borderRadius: "12px",
                  }}
                  loading="lazy"
                  onClick={(e) => {
                    e.preventDefault();
                    // scrollToTop();
                    // navigate(`listing-two/${detailsData.id}`);
                  }}
                />
              ) : detailsData?.documents?.length > 0 ? (
                /\.(mp4|mov|avi)$/.test(
                  detailsData?.documents[0]?.documentName?.toLowerCase()
                ) ? (
                  <>
                    <video
                      style={{
                        width: "110px",
                        height: "86px",
                        borderRadius: "12px",
                      }}
                      src={detailsData?.documents[0]?.uploadDocumentUrl}
                      className="event_img"
                      //preload="none"
                      // autoPlay
                      controls
                      controlsList="nodownload"
                      // playsInline
                      // loop
                      muted
                      onClick={(e) => {
                        e.preventDefault();
                        // scrollToTop();
                        // navigate(`listing-two/${detailsData.id}`);
                      }}
                    >
                      <source
                        src={detailsData?.documents[0]?.uploadDocumentUrl}
                      />
                    </video>
                  </>
                ) : (
                  <img
                    src={
                      detailsData?.documents[0]?.uploadDocumentUrl ||
                      "/static/expertise2.png"
                    }
                    className="event_img"
                    alt=""
                    style={{
                      width: "110px",
                      height: "86px",
                      borderRadius: "12px",
                    }}
                    loading="lazy"
                    onClick={(e) => {
                      e.preventDefault();
                      // scrollToTop();
                      // navigate(`listing-two/${detailsData.id}`);
                    }}
                  />
                )
              ) : (
                <img
                  style={{
                    width: "110px",
                    height: "86px",
                    borderRadius: "12px",
                  }}
                  src="/static/expertise2.png"
                  alt=""
                />
              )}
              <div className="title">
                {detailsData?.title && <h5>{detailsData?.title}</h5>}
                {detailsData?.listingEventType !== "V" && (
                  <p className="sub-title">
                    Address:{" "}
                    {detailsData?.scheduleDetails?.address?.address
                      ? detailsData?.scheduleDetails?.address?.address
                      : detailsData?.scheduleDetails[0]?.address?.address
                      ? detailsData?.scheduleDetails[0]?.address?.address
                      : ""}
                  </p>
                )}
                <div className="d-sm-none">
                  {detailsData?.listingType === "S" ||
                  detailsData?.listingType === "SPECIAL" ? (
                    <button className="session_btn_popup">
                      <img src="/static/Sport-white.svg" alt="Sport Balls" />
                      Session
                    </button>
                  ) : detailsData?.listingType === "P" ? (
                    <button
                      className="package_btn_popup"
                      style={{ padding: "10px" }}
                    >
                      <img src="/static/box-white.svg" alt="Sport Balls" />
                      Package
                    </button>
                  ) : (
                    <button
                      className="team_btn_popup"
                      style={{ padding: "10px" }}
                    >
                      <img src="/static/people-white.svg" alt="Sport Balls" />
                      Team
                    </button>
                  )}
                </div>
              </div>
              <div className="d-none d-sm-block">
                {detailsData?.listingType === "S" ||
                detailsData?.listingType === "SPECIAL" ? (
                  <button className="session_btn_listing">
                    <img src="/static/Sport-white.svg" alt="Sport Balls" />
                    Session
                  </button>
                ) : detailsData?.listingType === "P" ? (
                  <button
                    className="package_btn_listing"
                    style={{ padding: "10px" }}
                  >
                    <img src="/static/box-white.svg" alt="Sport Balls" />
                    Package
                  </button>
                ) : (
                  <button
                    className="team_btn_listing"
                    style={{ padding: "10px" }}
                  >
                    <img src="/static/people-white.svg" alt="Sport Balls" />
                    Team
                  </button>
                )}
              </div>
            </div>
            <div className="share_options">
              <span className="flex-fill" onClick={() => handleCopy()}>
                <img src="/static/copy.svg" alt="Copy icon" />
                Copy Link
              </span>
              <span className="flex-fill" onClick={handleEmailShare}>
                <img src="/static/mail.svg" alt="Mail icon" />
                Email
              </span>
              {/* <span onClick={handleSmsShare}>
                            <img src="/static/message.svg" alt="Message icon" />
                            Message
                          </span> */}
              <span className="flex-fill" onClick={handleSmsShare}>
                <img src="/static/message.svg" alt="Message icon" />
                Message
              </span>
              <span className="flex-fill" onClick={handleWhatsAppShare}>
                <img src="/static/whatsapp.svg" alt="WhatsApp icon" />
                WhatsApp
              </span>
              <span className="flex-fill" onClick={handleMessengerShare}>
                <img src="/static/messenger.svg" alt="Messenger icon" />
                Messenger
              </span>
              <span className="flex-fill" onClick={handleFacebookShare}>
                <img src="/static/facebook.svg" alt="Facebook icon" />
                Facebook
              </span>
              <span className="flex-fill" onClick={handleTwitterShare}>
                <img src="/static/twitter.svg" alt="Twitter icon" />
                Twitter
              </span>
            </div>
            <button
              className="mt-5 d-lg-none back-btn"
              onClick={handleNotShowShare}
            >
              Back
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewListing;

import React from "react";
import Button from "../../../global/Button";
import { BUTTON_TEXT } from "../../../utils/StringConstant";

interface SaveEventPopupProps {
  onClose: any;
  discard?: any;
  reCheck: any;
  showDiscard?: any;
  onContinue?: any;
  title: String;
}
const SavePackageEvent = (props: SaveEventPopupProps) => {
  const { onClose, reCheck, title, onContinue } = props;

  return (
    <div className={"modal show"}>
      <div className="modal-section draft_popup">
        <div className="modal-top-part mb-3">
          <button className="close">
            <img
              src="/static/dismiss-cross-round-black.svg"
              alt=""
              onClick={onClose}
            />
          </button>
          <h5>{title}</h5>
          <div className="popup-subhead">
            Be accurate and detailed in your Fitness Mates event listing to
            attract more users.
          </div>
          <div className="action_btns">
            <Button
              btnTxt={BUTTON_TEXT.RECHECK_DRAFT}
              btnImg="/static/arrow-back.svg"
              onClick={() => reCheck()}
            />
          </div>
        </div>
        <Button
          btnTxt={BUTTON_TEXT.SAVE_CONTINUE}
          className={"backtologin"}
          onClick={onContinue}
        />
      </div>
    </div>
  );
};

export default React.memo(SavePackageEvent);

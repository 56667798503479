import { useRef } from "react";
import { showToast } from "../../../utils";
import { apiConstants } from "../../../utils/Constants";
import Button from "../../../global/Button";
import InputField from "../../../global/InputField";
import { BUTTON_TEXT, HEADINGS, MESSAGE } from "../../../utils/StringConstant";

interface DraftPopUpProps {
  onClose?: any;
  handleBankDetails?: any;
  control?: any;
  errors?: any;
  setValue?: any;
  isEditMode?: boolean;
  watch?: any;
}

const AddBankAccountPopup = (props: DraftPopUpProps) => {
  const {
    onClose,
    handleBankDetails,
    control,
    errors,
    setValue,
    isEditMode = false,
    watch,
  } = props;

  const bsbNumberRef = useRef(null);
  const accNumberRef = useRef(null);
  const handleSave = () => {
    if (!watch("bNumber") || !watch("aNumber") || !watch("bName")) {
      showToast(MESSAGE.FILL_ALL_DETAILS, "error");
    } else if (watch("bNumber")?.length !== 6) {
      showToast(MESSAGE.INVALID_BSB_NUMBER, "error");
    } else if (
      String(watch("aNumber"))?.length > 10 ||
      String(watch("aNumber"))?.length < 6
    ) {
      showToast(MESSAGE.INVALID_ACCOUNT_NUMBER, "error");
    } else {
      setValue(apiConstants.bsb_number, watch("bNumber"));
      setValue(apiConstants.account_number, watch("aNumber"));
      setValue(apiConstants.bank_name, watch("bName"));
      handleBankDetails();
      setValue("bNumber", "");
      setValue("aNumber", "");
      setValue("bName", "");
    }
  };
  const handleCancel = () => {
    setValue("bNumber", "");
    setValue("aNumber", "");
    setValue("bName", "");
    onClose();
  };

  return (
    <div className={"modal show"}>
      <div className="stripe_acc_pop">
        <div className="modal-top-part">
          <div className="header_container">
            <img src="/static/closeBorder.svg" alt="" onClick={onClose} />
          </div>

          <div className="modal_stripe_heading">
            {isEditMode
              ? HEADINGS.EDIT_BANK_DETAILS
              : HEADINGS.ADD_BANK_DETAILS}
          </div>
          <div className="bank_details_form">
            <InputField
              key="bName"
              type={"text"}
              name={"bName"}
              className={"floating_input"}
              inputLabel={"Bank Name"}
              placeholder={"Enter the Bank name"}
              control={control}
              errors={errors}
              setValue={setValue}
            />
            <InputField
              key="bNumber"
              type={"number"}
              name={"bNumber"}
              className={"floating_input"}
              inputLabel={"BSB Number"}
              placeholder={"123-456"}
              control={control}
              errors={errors}
              setValue={setValue}
              maxlength={6}
              reference={bsbNumberRef}
            />
            <InputField
              key="aNumber"
              type={"number"}
              name={"aNumber"}
              className={"floating_input"}
              inputLabel={"Bank Account Number"}
              placeholder={"0111-334-344"}
              control={control}
              maxlength={10}
              errors={errors}
              setValue={setValue}
              reference={accNumberRef}
            />
          </div>
        </div>
        <Button
          btnTxt={
            isEditMode ? HEADINGS.EDIT_BANK_DETAILS : HEADINGS.ADD_BANK_DETAILS
          }
          className={"stripe_existing"}
          onClick={() => handleSave()}
        />
        <Button
          btnTxt={BUTTON_TEXT.CANCEL}
          className={"stripe_new"}
          onClick={handleCancel}
        />
      </div>
    </div>
  );
};

export default AddBankAccountPopup;
